package com.siriusxm.pia

import com.siriusxm.content.management.console.applications.transcription.TranscriptionAPI
import com.siriusxm.content.management.console.applications.transcription.TranscriptionContext
import com.siriusxm.content.management.console.applications.transcription.Transcriptions
import com.siriusxm.pia.cognito.CognitoClient
import com.siriusxm.pia.components.AudioPlayer
import com.siriusxm.pia.components.NotificationsConfig
import com.siriusxm.pia.rest.epg.EPGApiClient
import com.siriusxm.pia.rest.unifiedaggregator.UnifiedAggregatorClient
import com.siriusxm.pia.utils.HashNavigator
import com.siriusxm.pia.utils.navigator
import com.siriusxm.pia.views.channelguide.EPG
import com.siriusxm.pia.views.mddb.MDDBApplication
import com.siriusxm.pia.views.mddb.MddbAPI
import com.siriusxm.pia.views.sports.SportsApplication
import de.jensklingenberg.ktorfit.Ktorfit
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.async
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.withContext
import kotlinx.serialization.json.Json
import org.jetbrains.compose.web.css.Style

/**
 * The main application.
 */
object Application {
    lateinit var configuration: Configuration
    lateinit var cognito: CognitoClient
    lateinit var notifications: NotificationsConfig
    var player: AudioPlayer? = null

    private val origin = window.location.origin

    val context: ApplicationContext by lazy {
        object : ApplicationContext {
            override val accessToken: String?
                get() = cognito.accessToken
            override val configuration: Configuration
                get() = Application.configuration
            override val refreshToken: String?
                get() = cognito.refreshToken

            override fun navigate(path: String) {
                navigator.navigate(path)
            }

            override fun showError(message: String, description: String?) {
                notifications.showError(message, description)
            }

            override fun info(message: String, description: String?) {
                notifications.info(message, description)
            }

            override val viewer: Viewer
                get() = this@Application.viewer
        }
    }

    val navigation: HashNavigator = HashNavigator {
        Style(AppStylesheet)
        renderPage(it)
    }

    init {
        navigator = navigation
    }

    private val applications = hashMapOf<String, ConsoleApplication?>()

    /**
     * Access to the API
     */
    lateinit var api: IngestionAPI

    /**
     * Access to podcast ingestion audit API
     */
    lateinit var ingestionAuditAPI: IngestionAuditAPI

    /**
     * Access to the Transcription API
     */
    lateinit var transcriptionAPI: TranscriptionAPI

    /**
     * Access to the Unified Aggregator API
     */
    lateinit var aggregator: UnifiedAggregatorClient

    /**
     * Access to the MDDB API
     */
    lateinit var mddbAPI: MddbAPI

    /**
     * Access to the EPG APIs
     */
    lateinit var epgAPI: EPGApiClient

    suspend fun run() {
        configuration = config()
        val pkceEnabled = configuration.pkceEnabled.toBooleanStrictOrNull() ?: false
        cognito = CognitoClient(
            configuration.authUrl,
            configuration.clientId,
            origin,
            "sxm",
            "openid email Transcription/rest-api DynamicRadio/visibility-flags MDDBEventBus/rest-api UnifiedCatalogAPI/sxm:unified-catalog:entities:read EPG/sxm:unified-catalog:entities:read UnifiedCatalogAPI/sxm:unified-catalog:entities:publish UnifiedCatalogAPI/sxm:unified-catalog:management:read UnifiedCatalogAPI/sxm:unified-catalog:management:write UnifiedCatalogAPI/sxm:unified-catalog:backfill:start Sports/sxm:unified-catalog:entities:read",
            pkceEnabled
        )
        cognito.init()

        if (!cognito.isLoggedIn()) {
            withContext(Dispatchers.Main) {
                cognito.login()
            }
        } else {
            api = IngestionAPI(configuration.apiUrl) {
                cognito.accessToken!!
            }
            ingestionAuditAPI = IngestionAuditAPI(configuration.ingestionAuditUrl) {
                cognito.accessToken!!
            }

            transcriptionAPI = TranscriptionAPI(configuration.transcriptionApiUrl) {
                cognito.accessToken!!
            }
            val httpClient = HttpClient {
                install(ContentNegotiation) {
                    json(Json { isLenient = true; ignoreUnknownKeys = true })
                }
                expectSuccess = true
                install(Auth) {
                    bearer {
                        loadTokens {
                            BearerTokens(cognito.accessToken!!, cognito.refreshToken!!)
                        }
                    }
                }
            }

            aggregator = Ktorfit.Builder()
                .baseUrl(configuration.unifiedAggregatorApiUrl)
                .httpClient(httpClient)
                .build().create()

            epgAPI = Ktorfit.Builder()
                .baseUrl(configuration.epgApiUrl)
                .httpClient(httpClient)
                .build().create()

            mddbAPI = MddbAPI(configuration.mddbApiUrl) {
                cognito.accessToken!!
            }

            Transcriptions = TranscriptionContext(
                transcriptionAPI,
                navigation,
                aggregator,
                api,
                {
                    notifications
                },
                {
                    viewer
                },
                configuration.transcriptionEditorBaseUrl
            )

            navigation.run()
        }
    }

    /**
     * Load configuration and applications.
     */
    private suspend fun config(): Configuration {
        return coroutineScope {
            val httpClient = HttpClient {
                install(ContentNegotiation) {
                    json(Json { isLenient = true; ignoreUnknownKeys = true })
                }
            }
            val configJob = async {
                httpClient.get("$origin/config.json").body<Configuration>()
            }

            // applications are loaded separately.
            val applicationJob = async {
                httpClient.get("$origin/applications.json").body<List<ApplicationDescriptor>>()
            }

            configJob.await().copy(
                applications = applicationJob.await()
            )
        }
    }

    /**
     * Get the viewer of the application.
     */
    val viewer: Viewer by lazy {
        Viewer(cognito.idToken)
    }

    /**
     * Get the initialized application
     */
    fun getApplication(key: String): ConsoleApplication? {
        return applications.getOrPut(key) {
            when (key) {
                "mddb" -> MDDBApplication()
                "epg" -> EPG()
                "sports" -> SportsApplication()

                else -> null
            }?.apply {
                initialize(this@Application.context)
            }
        }
    }
}


/**
 * Shorthand for navigation
 */
fun Application.navigate(path: String) = navigation.navigate(path)