package com.siriusxm.pia.views.sports

import androidx.compose.runtime.Composable
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.utils.toLocalExtendedDateTimeString
import com.siriusxm.pia.views.channelguide.aggregatorIconLink
import com.siriusxm.unifiedcontent.sports.Airing
import com.siriusxm.unifiedcontent.sports.CompetitionParticipant
import com.siriusxm.unifiedcontent.sports.CompetitiveEvent
import com.siriusxm.unifiedcontent.sports.CompetitiveEventStatus
import contentingestion.unifiedmodel.Image
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Composable
fun eventsGridAdvanced(
    app: SportsApplication,
    allEvents: List<CompetitiveEvent>,
    selectorMap: Map<String, List<CompetitiveEvent>>? = null,
    selectedKey: String? = null,
    optionTextMap: Map<String, String>? = null,
    keySortFn: ((Set<String>) -> Set<String>)? = null,
    sortFn: ((List<CompetitiveEvent>) -> List<CompetitiveEvent>)? = null,
    cb: (filter: String?) -> Unit = {}
) {
    if (selectorMap != null) {
        Div({
            style {
                marginTop(10.px)
            }
        }) {
            buildSelect(
                selectedKey,
                selectorMap.keys,
                optionTextMap = optionTextMap,
                sortSelectOptions = false
            ) {
                cb(it)
            }
        }
    }

    if (selectedKey == null) {
        Div({ classes(SportsStyles.subheader) }) {
            Text("Showing ${allEvents.size} events")
        }
    }

    val keys = if (keySortFn != null && selectorMap?.keys != null) {
        keySortFn(selectorMap.keys)
    } else {
        selectorMap?.keys
    } ?: listOf()

    if (keys.size == 0) {
        renderSelectedItems(app, allEvents, optionTextMap, null, selectedKey, allEvents, sortFn)
    } else {
        keys.forEach {
            val selectedItems = selectorMap?.get(it)
            renderSelectedItems(app, selectedItems, optionTextMap, it, selectedKey, allEvents, sortFn)
        }
    }
}

@Composable
private fun renderSelectedItems(
    app: SportsApplication,
    selectedItems: List<CompetitiveEvent>?,
    optionTextMap: Map<String, String>?,
    key: String? = null,
    selectedKey: String? = null,
    allEvents: List<CompetitiveEvent>,
    sortFn: ((List<CompetitiveEvent>) -> List<CompetitiveEvent>)?
) {
    if (selectedItems != null && selectedItems.size > 0) {
        val header = optionTextMap?.get(key)

        if (selectedKey == null || selectedKey == key) {
            if (selectedKey != null) {
                Div({ classes(SportsStyles.subheader) }) {
                    Text("Showing ${selectedItems.size} of ${allEvents.size} events")
                }
            }

            if (header != null) {
                Div({ classes(SportsStyles.subheader) }) {
                    Text(header)
                }
            }

            val sortedEvents = if (sortFn != null) {
                sortFn(selectedItems)
            } else {
                selectedItems
            }

            boxTable {
                items(sortedEvents)
                column {
                    content { event ->
                        eventItem(app, event)
                    }
                }
            }
        }
    }
}

@Composable
fun eventItem(app: SportsApplication, event: CompetitiveEvent) {
    fun toBoxScoreRows(): MutableList<BoxScoreRow> {
        val boxScoreRows = mutableListOf<BoxScoreRow>()

        val awayTeam = event.awayTeam()
        val awayFinal = awayTeam?.id?.let { event.status.competitorScore(it) }
        val homeTeam = event.homeTeam()
        val homeFinal = homeTeam?.id?.let { event.status.competitorScore(it) }

        if (awayTeam != null) {
            boxScoreRows.add(
                BoxScoreRow(
                    awayTeam.id,
                    awayTeam.name,
                    getPreferredTeamImage(awayTeam)?.let { getAbsoluteUrl(app.context, it) },
                    listOf(), awayFinal.toString()
                )
            )
        }
        if (homeTeam != null) {
            boxScoreRows.add(
                BoxScoreRow(
                    homeTeam.id,
                    homeTeam.name,
                    getPreferredTeamImage(homeTeam)?.let { getAbsoluteUrl(app.context, it) },
                    listOf(), homeFinal.toString()
                )
            )
        }
        return boxScoreRows
    }

    @Composable
    fun completedEventList(boxScores: List<BoxScoreRow>) {
        Div({ classes(SportsStyles.metadata) }) {
            table<BoxScoreRow> {
                items(boxScores)

                column {
                    content {
                        Div({
                            style {
                                display(DisplayStyle.Flex)
                                flexDirection(FlexDirection.Row)
                                alignItems(AlignItems.Center)
                                width(100.px)
                            }
                        }) {
                            if (it.imageUrl != null) {
                                A("#sports/team/${it.teamId}") {
                                    Img(it.imageUrl) {
                                        style {
                                            width(64.px)
                                            height(64.px)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                column {
                    content {
                        Div({
                            classes(SportsStyles.metadata)
                            style {
                                width(300.px)
                            }
                        }) {
                            Div({ classes(SportsStyles.entityInfo) }) {
                                A("#sports/team/${it.teamId}") {
                                    Text(it.teamName)
                                }
                            }
                        }
                    }
                }

                column {
                    content {
                        Div({
                            classes(SportsStyles.entityInfo)
                            style {
                                width(25.px)
                            }
                        }) { Text(it.finalValue) }
                    }
                }
            }
        }
    }

    @Composable
    fun upcomingGamesList() {
        Span {
            event.participants().forEach {
                val img = getPreferredTeamImage(it)
                if (img != null) {
                    Span {
                        Img(src = getAbsoluteUrl(app.context, img)) {
                            style {
                                width(60.px)
                                height(60.px)
                                margin(5.px)
                            }
                        }
                    }
                } else {
                    Span {
                        Img(src = "images/blank.png") {
                            style {
                                width(60.px)
                                height(60.px)
                                margin(5.px)
                            }
                        }
                    }
                }
            }
        }
        Div({
            classes(SportsStyles.metadata)
            style {
                width(300.px)
            }
        }) {
            Div({ classes(SportsStyles.entityInfo) }) {
                Text(event.name())
            }
            Div({ classes(SportsStyles.entitySubInfo) }) {
                Text(event.start?.toLocalExtendedDateTimeString(false) ?: "")
            }
            Div({ classes(SportsStyles.entitySubInfo) }) {
                Text(event.state.name)
            }
        }
        val airings = event.airings()
        if (airings != null) {
            Span({
                style {
                    width(300.px)
                }
            }) {
                boxTable<Airing> {
                    items(airings.sortedBy { it.channelNumber })
                    column {
                        width = 250.px
                        content {
                            Text(it.channelName ?: "")
                        }
                    }
                    column {
                        content {
                            Text(it.channelNumber?.toString() ?: "")
                        }
                    }
                    column {
                        content {
                            Text(it.coverageType.name)
                        }
                    }
                    column {
                        content {
                            A(href = "#channelguide/channels/${it.channelId}") {
                                icon("lists") {
                                    size = IconSize.SMALL
                                    title = "View ${it.channelName} in Channel Guide"
                                }
                            }
                            aggregatorIconLink(it.channelId, iconSize = IconSize.SMALL)
                        }
                    }
                }
            }
        }
    }

    @Composable
    fun eventContent() {
        Div({ classes(SportsStyles.eventItem) }) {
            val preferredImage = getEventImage(event)
            if (preferredImage != null) {
                Div({
                    style {
                        width(60.px)
                        height(60.px)
                    }
                }) {
                    Img(src = getAbsoluteUrl(app.context, preferredImage)) {
                        style {
                            width(60.px)
                            height(60.px)
                        }
                    }
                }
            }
            if (event.completed() || event.live()) {
                val boxScoreRows = toBoxScoreRows()
                completedEventList(boxScoreRows)
            } else {
                upcomingGamesList()
            }
        }
    }

    Div({ classes(SportsStyles.hoverStyle) }) {
        A("#sports/event/${event.id}?returnUrl=${encodeURIComponent(window.location.hash)}") {
            eventContent()
        }
    }
}


fun CompetitiveEventStatus?.competitorScore(competitorId: String): Int? {
    val statuses = this?.scores?.filter { it.competitor.id == competitorId }
    return statuses?.first()?.value
}

fun getEventImage(event: CompetitiveEvent): Image? =
    null // event.images?.get(ImagePurpose.TILE)?.get(ImageAspectRatio.ASPECT_1X1)?.default

fun CompetitiveEvent.participants(): List<CompetitionParticipant> {
    val participants = mutableListOf<CompetitionParticipant>()
    status?.scores?.forEach {
        if (!participants.contains(it.competitor)) {
            participants.add(it.competitor)
        }
    }
    return participants
}