package com.siriusxm.pia.views.unifiedaggregator.batch

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.dialogField
import com.siriusxm.pia.components.dialogView
import com.siriusxm.pia.components.serviceContentView
import com.siriusxm.pia.components.waitUntilNonNull
import com.siriusxm.pia.rest.unifiedaggregator.BatchDescriptor
import com.siriusxm.pia.rest.unifiedaggregator.BatchingRequest
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import com.siriusxm.pia.views.unifiedaggregator.typeSelect
import contentingestion.aggregator.EntityTypeConfiguration
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.buildJsonArray
import kotlinx.serialization.json.buildJsonObject
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.dom.Option
import org.jetbrains.compose.web.dom.Select
import org.jetbrains.compose.web.dom.Text

val ENTITY_FILTER = "com.siriusxm.unifiedcontent.batch.EntityFilter"

@Composable
fun batchJob(aggregator: AggregatorService) {
    var batchJobs by remember { mutableStateOf<List<BatchDescriptor>?>(null) }
    var selectedJob by remember { mutableStateOf<BatchDescriptor?>(null) }

    LaunchedEffect(true) {
        try {
            val jobs = aggregator.api.availableBatchJobs()
            batchJobs = jobs
            selectedJob = jobs.firstOrNull()
        } catch (t: Throwable) {
            Application.notifications.showError("The operation could not be completed", t.message)

        }
    }

    var selectedTypes by remember { mutableStateOf<Collection<EntityTypeConfiguration>>(emptyList()) }

    serviceContentView {
        waitUntilNonNull(batchJobs) {
            dialogView("Start a batch job") {
                content {
                    dialogField("Job Type") {
                        Select({
                            onChange { update ->
                                selectedJob = batchJobs?.find { it.batchId == update.value }
                            }
                        }) {
                            batchJobs?.forEach {
                                Option(it.batchId, {
                                    if (selectedJob?.batchId == it.batchId) {
                                        selected()
                                    }
                                }) {
                                    Text(it.batchId)
                                }
                            }
                        }
                    }

                    selectedJob?.let { descriptor ->
                        if (descriptor.parameterType == ENTITY_FILTER) {
                            dialogField("types") {
                                aggregator.typeSelect(selectedTypes) {
                                    selectedTypes = it
                                }
                            }
                        }
                    }
                }
                if (selectedTypes.isNotEmpty()) {
                    action("Submit", true) {
                        if (selectedTypes.isNotEmpty()) {
                            aggregator.api.startBatchJob(
                                BatchingRequest(
                                batchId = selectedJob!!.batchId,
                                batchParameters = buildJsonObject {
                                    put("types", buildJsonArray {
                                        selectedTypes.forEach {
                                            add(JsonPrimitive(it.type))
                                        }
                                    })
                                }
                            ))
                        }
                    }
                }
            }
        }
    }
}