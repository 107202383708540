package com.siriusxm.pia.views.channelguide

import androidx.compose.runtime.Composable
import com.siriusxm.pia.components.IconSize
import com.siriusxm.pia.components.IconType
import com.siriusxm.pia.components.icon
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLAnchorElement

/**
 * Render a link to navigate to the aggregator for an element.
 */
@Composable
fun aggregatorIconLink(
    id: String?,
    iconSize: IconSize = IconSize.TINY,
    linkAttrs: AttrBuilderContext<HTMLAnchorElement>? = null
) {
    val attrs = if (linkAttrs == null) {
        {
            classes("aggregator-icon-link")
        }
    } else {
        linkAttrs
    }
    if (id != null) {
        A(href = "#aggregator/entity/${id}", attrs = attrs) {
            icon("database_search") {
                type = IconType.SYMBOLS
                size = iconSize
                title = "View in Aggregator"

                style {
                    property("vertical-align", "top")
                }
            }
        }
    }
}