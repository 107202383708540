package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.client.api.ImageSpecInput
import com.siriusxm.pia.client.api.client.Show
import com.siriusxm.pia.components.*
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

/**
 * The main show page.
 */
@Composable
fun showInfo(showId: String, section: String? = null, episodeGuid: String? = null) {
    var show by remember { mutableStateOf<Show?>(null) }

    LaunchedEffect(showId) {
        Application.api.query {
            show(showId) {
                id
                title
                description

                episodeSummary {
                    count
                }

                images {
                    src
                    color

                    sizes(listOf(ImageSpecInput(600, 600, 100)))
                }

                sources {
                    url
                    bundles {
                        id
                    }
                    bundleId
                    active
                }

                csShow {
                    csId
                }

                configuration {
                    active
                    directoryDetails {
                        this.showId
                        this.showUrl
                        this.directory {
                            directoryId
                            directoryName
                        }
                    }
                }
            }

        }.show.let {
            show = it
        }
    }

    if (show != null) {
        entityView({
            title = show!!.titleOrUnknown()

            if (show?.configuration?.active == false) {
                subTitle = "Inactive"
            }

            if (Application.viewer.contentEditor) {
                action("Edit") {
                    Application.navigation.navigate("podcasts/show/${showId}?action=edit")
                }
            }

            action {
                title = "Re-sync"
                primary = true
                showProgressOnAction = true
                action {
                    Application.api.mutate {
                        show(show!!.id) {
                            feeds {
                                reset(true)
                            }
                        }
                    }
                }
            }
        }) {
            box {
                Div({
                    style {
                        display(DisplayStyle.Flex)
                        gap(10.px)
                    }
                }) {
                    Div {
                        show!!.images?.firstOrNull()?.let {
                            Img(it.sizes.firstOrNull() ?: it.src!!, attrs = {
                                style {
                                    maxWidth(150.px)
                                    maxHeight(150.px)
                                }
                            })
                        }
                    }

                    detailGrid {
                        detail("ID", show?.csShow?.csId)

                        detail("Directories") {
                            if (show?.configuration?.active == false) {
                                Text("NONE. This show is inactive.")
                            } else {
                                show?.configuration?.directoryDetails?.forEach {
                                    Div {
                                        A(href = it.showUrl, attrs = {
                                            target(ATarget.Blank)
                                        }) {
                                            Text(it.directory.directoryName)
                                        }
                                    }
                                }
                            }
                        }

                        show?.episodeSummary?.count?.let {
                            detail("Episodes", it.toString())
                        }

                        show?.sources?.let {
                            val hasPremium = it.any { !it.bundles.isNullOrEmpty() }
                            val hasFree = it.any { it.bundles.isNullOrEmpty() }

                            detail("Type") {
                                val type = if (hasFree && hasPremium) {
                                    "Freemium"
                                } else if (hasPremium) {
                                    "Premium"
                                } else {
                                    "Free"
                                }
                                Text(type)
                            }
                        }
                    }
                }
            }

            tabView {
                onSelect {tab ->
                    console.log("Tab callback for: $tab")
          //          selectedEpisodeGuid = null
                    Application.navigation.push(
                        "podcasts/show/${showId}/${tab.lowercase()}",
                        "${show?.title}: $tab",
                        true
                    )
                    true
                }
                this.selection = section

                tab("Content") {
                    tabContent {
                        showContent(showId)
                    }
                }

                tab("Episodes") {
                    if (episodeGuid != null) {
                        breadcrumbs {
                            crumb("< Back to Episode List", "podcasts/show/${showId}/episodes")
                        }
                        episode(showId, episodeGuid)
                    } else {
                        showEpisodes(showId)
                    }
                }
                tab("Configuration") {
                    tabContent {
                        showSources(show!!, show!!.sources.orEmpty())
                        showConfiguration(showId)
                        showContentLinks(showId)
                    }
                }

                tab("Log") {
                    tabContent {
                        showErrors(showId)
                        showAudit(showId)
                    }
                }

                tab("Metrics") {
                    tabContent {
                        showMetrics(showId)
                    }
                }
            }
        }
    } else {
        spinner()
    }
}

/**
 * The title of the show, or if the title isn't available, the id
 */
fun Show.titleOrUnknown(): String {
    val idToShow = (if (this.hasField("csShow")) {
        csShow?.csId
    } else null) ?: this.id
    return title ?: "Unknown show: $idToShow"
}