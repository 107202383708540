package com.siriusxm.pia.views.unifiedaggregator.backfill

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import com.siriusxm.pia.views.unifiedaggregator.typeSelect
import contentingestion.aggregator.*
import io.ktor.client.plugins.*
import io.ktor.client.statement.*
import kotlinx.coroutines.TimeoutCancellationException
import kotlinx.coroutines.delay
import kotlinx.coroutines.withTimeout
import kotlin.time.Duration.Companion.seconds

/**
 * Shows a dialog to allow for testing of backfill targets and roles.
 */
@Composable
fun startBackfill(
    aggregator: AggregatorService, copyTarget: String? = null,
    copyRole: String? = null,
    copyTypes: Collection<EntityTypeConfiguration>? = null
) {
    var success by remember { mutableStateOf<Boolean?>(null) }
    var errors by remember { mutableStateOf<List<String>?>(null) }

    var role by remember { mutableStateOf<String?>(copyRole) }
    var targetArn by remember { mutableStateOf<String?>(copyTarget) }
    var types by remember { mutableStateOf(copyTypes ?: emptyList()) }

    /**
     * Run the test
     */
    suspend fun testBackfill(role: String, targetArn: String) {
        try {
            val testResult = aggregator.api.testBackfill(
                BackfillTestRequest(targetArn, role)
            )
            success = testResult.success
            if (!testResult.success) {
                errors = listOfNotNull(testResult.message)
            } else {
                errors = emptyList()
            }
        } catch (e: ClientRequestException) {
            if (e.response.status.value == 400) {
                val description = e.response.bodyAsText().takeIf { it.isNotBlank() } ?: "Unknown error"
                errors = listOf(description)
            }
        } catch (t: Throwable) {
            t.printStackTrace()
            Application.notifications.showError(
                "Backfill test failed",
                t.message
            )
        }
    }

    // Execute the backfill.
    suspend fun runBackfill() {
        if (targetArn == null) {
            errors = listOf("You must enter a target ARN")
            return
        }
        if (types.isEmpty()) {
            errors = listOf("You must select at least one type")
            return
        }

        try {
            val status = aggregator.api.startBackfill(
                BackfillRequest(
                    identifiers = Identifiers.MAPPED,
                    targetArn!!,
                    role,
                    types.map {
                        it.schema
                    },
                    filter = BackfillDataFilter()
                )
            )

            // wait for the job to be 'realized', and then navigate to it.
            try {
                withTimeout(10.seconds) {
                    while (true) {
                        try {
                            aggregator.api.backfill(status.jobId)
                            break
                        } catch (t: Throwable) {
                            delay(1.seconds)
                        }
                    }
                    aggregator.navigate("backfill/${status.jobId}")
                }
            } catch (e: TimeoutCancellationException) {
                aggregator.navigate("backfill")
            }

        } catch (t: Throwable) {
            t.printStackTrace()
            Application.notifications.showError("Unable to start backfill", t.message)
        }
    }

    dialogView("Run Backfill") {
        content {
            dialogField("Target ARN", "The target ARN of the backfill (Kinesis Stream, Event Bridge Bus, etc.)") {
                simpleTextField(targetArn) {
                    targetArn = it
                    success = null
                }
            }

            dialogField("Role ARN", "The ARN of the role used to publish data to the target.") {
                simpleTextField(role) {
                    role = it
                    success = null
                }
            }

            dialogField("Types", "Select the types to include in the backfill") {
                aggregator.typeSelect(types) {
                    types = it
                }
            }

            if (success == true) {
                notificationBox(
                    Notification(
                        NotificationStyles.infoBox, "Success!",
                        "Your backfill target and role are configured correctly!"
                    )
                ) {

                }
            }
            errors?.forEach { error ->
                notificationBox(Notification(NotificationStyles.errorBox, error, null)) {}
            }
        }

        this.action {
            title = "Cancel"
            enabled = true
            action {
                aggregator.navigate("backfill")
            }
        }

        this.action {
            title = "Start"
            primary = true
            enabled = !targetArn.isNullOrEmpty() && types.isNotEmpty()
            showProgressOnAction = true
            action {
                errors = null
                runBackfill()
            }
        }

        this.action {
            title = "Test"
            primary = true
            enabled = !targetArn.isNullOrEmpty()
            showProgressOnAction = true
            action {
                errors = null
                testBackfill(role ?: "", targetArn ?: "")
            }
        }
    }
}