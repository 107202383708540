package com.siriusxm.pia.views.unifiedaggregator.management

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import contentingestion.aggregator.Contact
import contentingestion.aggregator.ProducerDetails
import org.jetbrains.compose.web.dom.Text

@Composable
fun producerEditor(aggregator: AggregatorService, producer: ProducerDetails? = null) {
    var updatedProducer by remember { mutableStateOf(producer ?: ProducerDetails("")) }

    dialogView(if (producer != null) "Edit Producer" else "Create Producer") {
        /**
         * Save the producer, returns true if successful.
         */
        /**
         * Save the producer, returns true if successful.
         */
        suspend fun save(): Boolean {
            return validate {
                assertField(updatedProducer.id.length >= 5, "The id must be at least 5 characters")
                assertField(updatedProducer.name.orEmpty().length >= 5, "The name must be at least 5 characters")
                orElse {
                    try {
                        if (producer == null) {
                            aggregator.newProducer(updatedProducer)
                        } else {
                            aggregator.updateProducer(updatedProducer)
                        }
                        aggregator.refresh()
                    } catch (t: Throwable) {
                        errors.error("Unable to save producer: ${t.message}")
                    }

                }
            }.success
        }

        content {
            dialogField(
                "ID",
                if (producer == null) "Enter a unique id for the producer. The preferred format is `sxm/<workload-name>/<service-name>." else null
            ) {
                if (producer == null) {
                    simpleTextField(updatedProducer.id) {
                        updatedProducer = updatedProducer.copy(id = it ?: "")
                    }
                } else {
                    Text(updatedProducer.id)
                }
            }

            dialogField("Name", "A human readable name of the producer (i.e. Playback Services)") {
                simpleTextField(updatedProducer.name) {
                    updatedProducer = updatedProducer.copy(name = it)
                }
            }

            dialogField("Client ID", "The Cognito client id, for those producers that submit via API.") {
                simpleTextField(updatedProducer.clientId) {
                    updatedProducer = updatedProducer.copy(clientId = it ?: "")
                }
            }

                dialogField("Types", "This list of types that this producer is allowed to submit updates for.") {
                    scrollableCheckBoxSelector(
                        items = aggregator.entityTypes,
                        selectedItems = updatedProducer.allowedTypes.orEmpty().toSet(),
                        itemLabel = { it.name },
                        itemIdentifier = { it.type},
                        onSelectionChange = { newAllowedTypes ->
                            updatedProducer = updatedProducer.copy(
                                allowedTypes = newAllowedTypes.toList()
                            )
                        }
                    )
                }

            dialogField("Partials", "This list of partials that this producer is allowed to submit.") {
                scrollableCheckBoxSelector(
                    items = aggregator.partials,
                    selectedItems = updatedProducer.allowedPartials.orEmpty().toSet(),
                    itemLabel = { it.id },
                    itemIdentifier = { it.id},
                    onSelectionChange = { newAllowedPartials ->
                        updatedProducer = updatedProducer.copy(
                            allowedPartials = newAllowedPartials.toList()
                        )
                    }
                )
            }


            dialogField("Slack Channel", "The name of the Slack channel for support.") {
                simpleTextField(updatedProducer.slackChannel) {
                    updatedProducer = updatedProducer.copy(slackChannel = it ?: "")
                }
            }

            dialogField("JIRA Project Key", "The short key for the JIRA project for this producer.") {
                simpleTextField(updatedProducer.jiraProject) {
                    updatedProducer = updatedProducer.copy(jiraProject = it ?: "")
                }
            }

            dialogField("AWS Accounts", "The AWS account of this service.") {
                simpleTextField(updatedProducer.awsAccountId) {
                    updatedProducer = updatedProducer.copy(
                        awsAccountId = it
                    )
                }
            }

            dialogField("Contact Emails", "A comma separated list of emails of contacts for this producer.") {
                simpleTextField(updatedProducer.contact?.joinToString(",") { it.email }) {
                    updatedProducer = updatedProducer.copy(contact = it?.split(",")?.map { it.trim() }?.map {
                        Contact(it)
                    })
                }
            }

        }

        action {
            title = "Submit"
            primary = true
            showProgressOnAction = true
            this.action {
                if (save()) {
                    aggregator.navigate("producers/${encodeURIComponent(updatedProducer.id)}")
                }
            }
        }
        action("Cancel") {
            aggregator.navigate("producers")
        }
    }
}