package com.siriusxm.pia

import androidx.compose.runtime.Composable
import com.siriusxm.content.management.console.applications.transcription.transcriptions
import com.siriusxm.pia.Application.getApplication
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.views.homeDashboard
import com.siriusxm.pia.views.mddb.MddbAugmenter
import com.siriusxm.pia.views.podcasts.podcasts
import com.siriusxm.pia.views.unifiedaggregator.contentAggregator

@Composable
fun applicationRouting(route: Route) {
    route.switch {
        select("podcasts") {
            podcasts()
        }

        select("mddb") {
            (getApplication("mddb"))?.route(this)
        }

        select("transcriptions") {
            transcriptions()
        }

        select("aggregator") {
            this.contentAggregator(
                Application.context, Application.aggregator, listOf(
                MddbAugmenter(Application.mddbAPI)
            ))
        }

        select("sports") {
            (getApplication("sports"))?.route(this)
        }

        select("channelguide") {
            (getApplication("epg"))?.route(this)
        }

        default {
            homeDashboard()
        }
    }
}