package com.siriusxm.content.management.console.applications.transcription

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.transcription.TranscriptionJob
import com.siriusxm.pia.rest.transcription.TranscriptionJobStatus
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.utils.toLocalDateTimeString
import kotlinx.datetime.Instant
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun transcriptionJobResultsTab(jobs: List<TranscriptionJob>, returnPath: String = "") {

    var entityTypeFilter : String? by remember { mutableStateOf(null) }

    if (jobs.isEmpty()) {
        Div {
            Text("There appear to be no jobs.")
        }
    } else {

        Div {
            detailGrid {
                detail("Entity Type Filter") {
                    val entityTypes = setOf("Podcast Episodes", "AOD Episodes")
                    buildSelect(entityTypeFilter, entityTypes) { changedFilter ->
                        entityTypeFilter = changedFilter
                    }
                }
            }
        }

        val filteredJobs = filterByEntityType(jobs, entityTypeFilter)
        tabView {
            tab("Results") {

                boxTable <TranscriptionJob> {
                    items(filteredJobs)

                    column {
                        title = "Typed Entity Id"
                        content { job ->
                            Div {
                                val typedEntityId = job.request.typedEntityId
                                A(href = transcriptionIdLink(typedEntityId, returnPath)) {
                                    Text(typedEntityId)
                                }
                            }
                        }
                    }

                    column {
                        title = "Aggregator Link"
                        content { job ->
                            Div {
                                val typedEntityId = job.request.typedEntityId
                                A(href = entityIdLink(typedEntityId, returnPath)) {
                                    Text("Link")
                                }
                            }
                        }
                    }

                    column {
                        title = "TranscriptionId"
                        content { job ->
                            Div {
                                Text(job.transcriptionId ?: "")
                            }
                        }
                    }

                    column {
                        title = "StartTime"
                        content { job ->
                            val instant = job.start?.let {
                                try {
                                    Instant.parse(it)
                                } catch (e: Exception) {
                                    null
                                }
                            }
                            Div {
                                Text(instant?.toLocalDateTimeString() ?: "")
                            }
                        }
                    }

                    column {
                        title = "EndTime"
                        content { job ->
                            val instant = job.end?.let {
                                try {
                                    Instant.parse(it)
                                } catch (e: Exception) {
                                    null
                                }
                            }
                            Div {
                                Text(instant?.toLocalDateTimeString() ?: "")
                            }
                        }
                    }

                    column {
                        title = "Provider"
                        content { job ->
                            Text("${job.provider}")
                        }
                    }

                    column {
                        title = "Fidelity"
                        content { job ->
                            Div {
                                Text("${job.fidelity}")
                            }
                        }
                    }

                    column {
                        title = "Status"
                        content { job ->
                            Div {
                                Text(job.status?.toString() ?: "")
                            }

                            if (Transcriptions.viewer.transcriptionAdmin) {
                                if (canBeRetried(job)) {
                                    button("Retry") {
                                        val currentPath = Transcriptions.navigation.path
                                        Transcriptions.navigation.navigate(
                                            "transcription-job-retry/${
                                                encodeURIComponent(
                                                    job.jobId
                                                )
                                            }?action=view&return=${encodeURIComponent(currentPath)}"
                                        )
                                    }
                                }
                            }
                        }
                    }

                    column {
                        title = "StatusDetails"
                        content { job ->
                            val statusDetails = job.statusDetails ?: job.statusDetail ?: ""
                            Div {
                                Text(statusDetails)
                            }
                        }
                    }

                    column {
                        title = "Vocabulary Dictionaries"

                        content { job ->
                            Div {
                                job.request.customVocabularyIds?.forEachIndexed { index, item ->
                                    if (index != 0) {
                                        Text(", ")
                                    }
                                    A("/#transcriptions/vocabulary/${encodeURIComponent(item)}?action=view&return=${encodeURIComponent(returnPath) }") {
                                        Text(item)
                                    }
                                }
                            }
                        }
                    }

                    column {
                        title = "Vocabulary Phrases"

                        content { job ->
                            Div {
                                Text(job.vocabularyPhrasesTruncated ?: "")
                            }
                        }
                    }

                    column {
                        title = "VocabularyLink"

                        content { job ->
                            Div {
                                Text(job.vocabularyPhrasesLink ?: "")
                            }
                        }
                    }
                }
            }
        }
    }
}

fun canBeRetried(job: TranscriptionJob) =
    listOf(TranscriptionJobStatus.FAILED, TranscriptionJobStatus.CANCELED).contains(job.status)

private fun filterByEntityType(jobs: List<TranscriptionJob>, entityTypeFilter: String?) : List<TranscriptionJob> {
    console.log("filterByEntityType: $entityTypeFilter")
    return jobs.filter {
        entityTypeFilter == null || getEntityType(it) == entityTypeFilter
    }
}

private fun getEntityType(job: TranscriptionJob) : String {
    return if (job.request.typedEntityId.startsWith("PE")) {
        "Podcast Episodes"
    } else if (job.request.typedEntityId.startsWith("AE")) {
        "AOD Episodes"
    } else {
        "Other"
    }
}

fun transcriptionIdLink(typedEntityId: String, returnPath: String) : String {
    return "#transcriptions/${encodeURIComponent(typedEntityId)}?action=view&return=${encodeURIComponent(returnPath) }"
}

private fun entityIdLink(typedEntityId: String, returnPath: String) : String {
    return "#aggregator/entity/${encodeURIComponent(typedEntityId)}?action=view&return=${encodeURIComponent(returnPath) }"
}