package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.*
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.boxContent
import com.siriusxm.pia.components.boxSpinner
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Pre
import org.jetbrains.compose.web.dom.Text

@Composable
fun feedContent(contentUrl: String) {
    var content by remember { mutableStateOf<String?>(null) }

    LaunchedEffect(contentUrl) {
        try {
            content = ContentFetch.fetchContent(contentUrl)
        } catch (e: Throwable) {
            content = "Content could not be fetched"
        }
    }

    box {
        if (content != null) {
            boxContent {
                Pre({
                    style {
                        maxWidth(100.percent)
                        maxHeight(500.px)
                        overflow("scroll")
                        fontSize((0.8).cssRem)
                    }
                }) {
                    Text(content ?: "")
                }
            }
        } else {
            boxSpinner("Loading feed content...")
        }
    }
}