package com.siriusxm.content.management.console.applications.transcription

import androidx.compose.runtime.*
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.spinner
import com.siriusxm.pia.rest.transcription.TranscriptionJob
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch

/**
 * The main transcription completed page.
 */

@Composable
fun transcriptionsCompletedPage(returnPath: String? = null) {

    var completedTranscriptions by remember { mutableStateOf(emptyList<TranscriptionJob>()) }
    val coroutineScope = rememberCoroutineScope()
    var loading by mutableStateOf(false)
    var loadingJob by mutableStateOf<Job?>(null)

    suspend fun lookupCompletedTranscriptions() {
        try {
            loading = true

            console.log("Begin looking for completed transcriptions")
            val completed = Transcriptions.api.getCompletedTranscriptions()
            val completedJobs = mutableListOf<TranscriptionJob>()
            completed.jobs.let { completedJobs.addAll(it) }
            completedTranscriptions = completedJobs

            console.log("Done looking update completed transcriptions")
        } catch (e: CancellationException) {
            // ignored
        } catch (t: Throwable) {
            console.log("Got an exception: $t")
            completedTranscriptions = emptyList()
        } finally {
            loading = false
        }
    }


    box({
        paddedContent = completedTranscriptions.isEmpty() || loading
        header({
            title = "Completed Transcriptions"
        })
    }) {
        if (loading) {
            spinner()
        } else if (completedTranscriptions.isEmpty() && loadingJob == null) {
            loadingJob = coroutineScope.launch {
                lookupCompletedTranscriptions()
            }
        } else {
            transcriptionJobResultsTab(completedTranscriptions, returnPath ?: "#transcriptions-completed")
        }
    }
}

