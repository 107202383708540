package com.siriusxm.pia.cognito

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class IdToken(
    val sub: String,

    @SerialName("cognito:groups")
    val cognitoGroups: List<String>,

    val email: String? = null
)