package com.siriusxm.pia.components

import androidx.compose.runtime.*
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

/**
 * This will show a delete confirmation dialog where the caller will be notified if the user cancels or allows the delete.
 * The details provides context for what will be deleted.
 */
@Composable
fun deleteConfirmationDialog(itemToDeleteDetails: String,
                             canBeUndone: Boolean = false,
                             onCancelCb:suspend () -> Unit,
                             onDeleteCb:suspend () -> Unit) {
    var deleteConfirmation by remember { mutableStateOf("") }


    dialogView("Delete") {
        action {
            title = "Cancel"
            action {
                onCancelCb()
            }
        }

        if (deleteConfirmation == "delete") {
            action {
                title = "Delete"
                primary = true
                showProgressOnAction = true
                action {
                    try {
                        onDeleteCb()
                    } catch (t: Throwable) {
//                        Application.notifications.showError(
//                            "Unable to delete $itemToDeleteDetails",
//                            t.message
//                        )
                    }
                }
            }
        }

        content {
            if (!canBeUndone) {
                messageBox(
                    """When you delete this item, it cannot be undone"""
                )
            }

            box({
                title = "Delete $itemToDeleteDetails?"
            }) {
                P {
                    Text("This operation will delete the item.")
                }
                P {
                    Text("To confirm, type 'delete' in the field below")
                }
                Input(InputType.Text) {
                    onInput { event ->
                        deleteConfirmation = event.value
                    }
                }
            }
        }
    }
}
