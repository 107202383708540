package com.siriusxm.pia.rest.unifiedaggregator

import contentingestion.unifiedmodel.ImageAspectRatio
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi
import kotlin.math.min

object ImageRenderingHelper {
    @OptIn(ExperimentalSerializationApi::class)
    val json = Json {
        explicitNulls = false
    }

    @OptIn(ExperimentalEncodingApi::class)
    fun getImageUrl(baseUrl: String, path: String, resize: ResizeParams? = null, format: FormatParams? = null): String {
        val imageSpec = ImageSpec(
            path,
            buildList {
                if (format != null) {
                    add(mapOf("format" to ImageParams(type = format.type)))
                }
                if (resize != null) {
                    add(mapOf("resize" to ImageParams(width = resize.width, height = resize.height)))
                }
            }
        )
        val jsonString = json.encodeToString(ImageSpec.serializer(), imageSpec)
        val base64 = Base64.encode(jsonString.encodeToByteArray())
        return "${baseUrl}$base64"
    }

    fun getImageWidth(aspectRatio: ImageAspectRatio, srcImageWidth: Int?, srcImageHeight: Int?, maxHeight: Int, maxWidth: Int) : Int {
        return when (aspectRatio) {
            ImageAspectRatio.ASPECT_1X1 -> maxWidth
            ImageAspectRatio.ASPECT_16X9 -> getRatiodWidth(16, 9, 250, 250)
            ImageAspectRatio.ASPECT_5X4 -> getRatiodWidth(5, 4, 250, 250)
            else -> maxWidth
        }
    }

    fun getImageHeight(aspectRatio: ImageAspectRatio, srcImageWidth: Int?, srcImageHeight: Int?, maxHeight: Int, maxWidth: Int) : Int {
        return when (aspectRatio) {
            ImageAspectRatio.ASPECT_1X1 -> maxHeight
                ImageAspectRatio.ASPECT_16X9 -> getRatiodHeight(16, 9, 250, 250)
            ImageAspectRatio.ASPECT_5X4 -> getRatiodHeight(5, 4, 250, 250)
            else -> maxWidth
        }
    }

    private fun getRatiodWidth(widthRatio: Int, heightRatio: Int, maxWidth: Int, maxHeight: Int) : Int {
        return if (widthRatio == heightRatio) {
            maxWidth
        } else if (widthRatio > heightRatio) {
            maxWidth
        } else {
            min(((widthRatio * maxWidth) / heightRatio) * maxWidth, maxWidth)
        }
    }

    private fun getRatiodHeight(widthRatio: Int, heightRatio: Int, maxWidth: Int, maxHeight: Int) : Int {
        return if (widthRatio == heightRatio) {
            maxWidth
        } else if (widthRatio > heightRatio) {
            min(((heightRatio * maxHeight) / widthRatio) * maxHeight, maxHeight)
        } else {
            maxHeight
        }
    }
}


@Serializable
data class ImageSpec(
    val key: String,
    val edits: List<Map<String, ImageParams>>? = null
)

@Serializable
data class ImageParams(
    val width: Int? = null,
    val height: Int? = null,
    val type: String? = null
)


data class ResizeParams(
    val width: Int,
    val height: Int
)

data class FormatParams(
    val type: String
)