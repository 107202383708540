package com.siriusxm.content.management.console.applications.transcription

import kotlinx.serialization.Serializable

@Serializable
data class AtlasEpisodeEntity(
    val name: String? = null,
    val type: String? = null,
    val show: String? = null,
    val description: String? = null,
    val media: List<AtlasMediaEntity>
)

@Serializable
data class AtlasMediaEntity(
    val url: String? = null,
    val bitrate: Int? = null,
    val format: String? = null,
    val duration: Int? = null,
    val authenticated: Boolean? = null
)