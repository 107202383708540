package com.siriusxm.pia.views.podcasts

import de.jensklingenberg.ktorfit.http.GET
import de.jensklingenberg.ktorfit.http.Headers
import de.jensklingenberg.ktorfit.http.Path
import kotlinx.serialization.Serializable

interface IngestionClient {
    @Headers("Content-Type: application/json")
    @GET("audit/shows/{requestedBy}")
    suspend fun auditShows(@Path("requestedBy") requestedBy: String): AuditShowResponse
}


@Serializable
data class AuditShowResponse(
    val totalShows: Int? = null,
    val activeShows: Int? = null,
    val stitcherOnlyShows: Int? = null,
    val sxmOnlyShows: Int? = null,
    val inactiveShows: Int? = null
)