package com.siriusxm.pia.views.channelguide

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.epg.decode
import com.siriusxm.unifiedcontent.entities.TypeCatalog
import contentingestion.unifiedmodel.*
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Composable
fun shows(epg: EPG) {
    var searchInput: String? by remember { mutableStateOf(null) }
    var searchSubmit: String? by remember { mutableStateOf(null) }
    var searchResults: List<Identifiable>? by remember { mutableStateOf(null) }

    suspend fun submitSearch(search: String) {
        val response = epg.aggregator.searchEntities(
            queryTerm = search,
            types = listOf(
                EntityType.EPISODE_LINEAR.name, EntityType.EPISODE_AUDIO.name, EntityType.SHOW.name,
                EntityType.EPISODE_VIDEO.name, EntityType.SHOW.name
            ).joinToString(","),
            from = null,
            size = 100,
            visible = null
        )
        searchResults = response.entities.mapNotNull {
            TypeCatalog.parseEntity(it) as? Identifiable
        }
        searchSubmit = null
    }

    LaunchedEffect(searchSubmit) {
        if (searchSubmit != null) {
            submitSearch(searchSubmit!!)
        }
    }

    serviceContentView({
        title = "Shows and Episodes"
    }) {
        Div({
            style {
                display(DisplayStyle.Flex)
                gap(1.em)
                marginTop(1.em)
            }
        }) {
            Div({
                style {
                    flexGrow(1)
                }
            }) {
                Input(InputType.Text) {
                    style {
                        width(100.percent)
                    }
                    placeholder("Search for shows and episodes")
                    value(searchInput ?: "")
                    onInput {
                        searchInput = it.value.ifBlank { null }
                    }
                    onKeyDown { event ->
                        if (event.key == "Enter") {
                            event.stopPropagation()
                            event.preventDefault()
                            searchSubmit = searchInput
//                            scope.launch {
//                                searchInput?.let { submitSearch(it) }
//                            }
                        }
                    }
                }
            }
        }

        @Composable
        fun entityHeader(type: EntityType) {
            val name = when (type) {
                EntityType.EPISODE_LINEAR -> {
                    "Live Episode"
                }

                EntityType.SHOW -> {
                    "Live Episode"
                }

                EntityType.EPISODE_AUDIO -> {
                    "Audio Episode"
                }

                EntityType.EPISODE_VIDEO -> {
                    "Video Episode"
                }

                else -> null
            }
            if (name != null) {
                Div({
                    style {
                        fontSize(.8.em)
                        fontWeight(700)
                        position(Position.Relative)
                        top(.8.em)
                    }
                }) {
                    Text(name)
                }
            }
        }

        Div {
            if (searchSubmit != null) {
                spinner()
            } else if (searchResults != null) {
                if (searchResults?.isNotEmpty() == true) {
                    searchResults?.forEach {
                        entityHeader(it.type)
                        when (it) {
                            is Show -> {
                                showSummaryItem(
                                    epg,
                                    it.copy(
                                        id = it.id.substringAfter(":")
                                    )
                                )
                            }

                            is VideoEpisode -> episodeSummaryItem(
                                epg,
                                it.copy(
                                    id = it.id.substringAfter(":")
                                )
                            )

                            is AudioEpisode -> episodeSummaryItem(
                                epg,
                                it.copy(
                                    id = it.id.substringAfter(":")
                                )
                            )

                            is LiveEpisode -> episodeSummaryItem(
                                epg,
                                it.copy(
                                    id = it.id.substringAfter(":")
                                )
                            )

                            else -> {}
                        }
                    }
                } else {
                    boxMessage("There are no results for this search")
                }
            }
        }
    }
}

/**
 * Renders a show page, complete with channel details, episodes, etc.
 */
/**
 * Renders a show page, complete with channel details, episodes, etc.
 */
@Composable
fun show(epg: EPG, showId: String) {
    var loading by remember { mutableStateOf<Boolean?>(null) }
    var show by remember { mutableStateOf<Show?>(null) }
    var episodes by remember { mutableStateOf<List<Episode>?>(null) }

    LaunchedEffect(showId) {
        loading = true

        val showJob = async {
            epg.fetchShow(showId)
        }
        val episodeJob = async {
            epg.api.getShowEpisodes(showId, 500, null).decode<Episode>().entities
        }
        listOf(showJob, episodeJob).awaitAll()

        show = showJob.await()
        episodes = episodeJob.await()

        loading = false
    }

    serviceContentView({
    }) {
        if (loading == true) {
            spinner(size = Size.LARGE)
        } else {

            if (show != null) {
                Div({
                    classes(ChannelGuideStyles.epgHeader)
                }) {
                    Div({
                        classes(ChannelGuideStyles.epgHeaderImage)
                    }) {
                        defaultImage(epg.context, show?.images, ImageAspectRatio.ASPECT_16X9, 186.px)
                    }

                    Div({
                        classes(ChannelGuideStyles.epgHeaderContent)
                    }) {
                        H1 {
                            show?.name?.let {
                                Text(it)
                            }
                        }
                        show?.description?.let {
                            P {
                                Text(it)
                            }
                        }
                    }
                }
            }
            tabView {
                tab("Episodes") {
                    episodes?.forEach {
                        episodeSummaryItem(epg, it)
                    }
                }
            }
        }
    }
}

/**
 * Get a list summary item for a show.
 */
/**
 * Get a list summary item for a show.
 */
@Composable
fun showSummaryItem(epg: EPG, show: Show) {
    val showUrl = "#channelguide/shows/${show.id}"

    Div({
        classes(ChannelGuideStyles.epgListItem)
        style {
            alignItems(AlignItems.FlexStart)
        }
    }) {
        Div({
            classes(ChannelGuideStyles.epgListItemImage)
        }) {
            defaultImage(epg.context, show.images, ImageAspectRatio.ASPECT_1X1, 128.px)
        }
        Div({
            classes(ChannelGuideStyles.epgListItemMain)
        }) {
            Div {
                H3 {
                    A(href = showUrl) {
                        Text(show.name)
                    }
                    aggregatorIconLink(show.id)

                }

                val description =
                    show.additionalDescriptions?.find { it.length == DescriptionLength.SHORT }?.description
                        ?: show.description
                description?.let {
                    P {
                        Text(it)
                    }
                }
            }
        }
        Div({
            classes(ChannelGuideStyles.epgListItemTrailing)
        }) {
            flags(
                show.flags, mapOf(
                    ShowFlag.MATURE to "explicit",
                    ShowFlag.PODCAST to "podcasts",
                    ShowFlag.VIDEO to "videocam",
                    ShowFlag.PLACEHOLDER to "place_item",
                    ShowFlag.EPISODIC to "newspaper",
                    ShowFlag.SXM_OWNED to "corporate_fare",
                )
            )
        }
    }
}