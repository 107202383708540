package com.siriusxm.pia.utils

fun Any.prettyPrint(): String {

    var indentLevel = 0
    val indentWidth = 4

    fun padding() = "".padStart(indentLevel * indentWidth)

    val toString = toString()

    val stringBuilder = StringBuilder(toString.length)

    var i = 0
    while (i < toString.length) {
        when (val char = toString[i]) {
            '(', '[', '{' -> {
                indentLevel++
                stringBuilder.appendLine(char).append(padding())
            }

            ')', ']', '}' -> {
                indentLevel--
                stringBuilder.appendLine().append(padding()).append(char)
            }

            ',' -> {
                stringBuilder.appendLine(char).append(padding())
                // ignore space after comma as we have added a newline
                val nextChar = toString.getOrElse(i + 1) { char }
                if (nextChar == ' ') i++
            }

            else -> {
                stringBuilder.append(char)
            }
        }
        i++
    }

    return stringBuilder.toString()
}

fun Any.prettyPrintXML(): String {
    val xml = toString()
    val indentStep = "    " // Indentation level (4 spaces)
    val result = StringBuilder()
    var currentIndent = 0
    var insideCData = false
    var lastTokenWasOpenTag = false

    // A regex to match XML tags, CDATA sections, and text content
    val regex = Regex("""(<!\[CDATA\[.*?\]\]>|<[^>]+>|[^<]+)""")

    xml.lines().flatMap { line ->
        regex.findAll(line).map { it.value }.toList()
    }.forEach { token ->
        when {
            token.startsWith("<![CDATA[") -> {
                if (lastTokenWasOpenTag) {
                    result.append("\n")
                }
                // Handle CDATA sections
                result.append(indentStep.repeat(currentIndent)).append(token).append("\n")
                insideCData = true
                lastTokenWasOpenTag = false
            }
            token.startsWith("</") -> {
                // Closing tag: If it's part of an inline structure, inline it
                if (lastTokenWasOpenTag) {
                    result.append(token).append("\n")
                    currentIndent--
                } else {
                    currentIndent--
                    result.append(indentStep.repeat(currentIndent)).append(token).append("\n")
                }
                insideCData = false
                lastTokenWasOpenTag = false
            }
            token.startsWith("<") && token.endsWith("/>") -> {
                // Self-closing tag: Keep the current indent
                result.append(indentStep.repeat(currentIndent)).append(token).append("\n")
                lastTokenWasOpenTag = false
            }
            token.startsWith("<") -> {
                // Opening tag: Append and increase indent
                if (lastTokenWasOpenTag) result.append("\n")
                result.append(indentStep.repeat(currentIndent)).append(token)
                currentIndent++
                lastTokenWasOpenTag = true
            }
            token.isNotBlank() -> {
                // Text content inside a tag
                val content = token.trim()
                if (lastTokenWasOpenTag) {
                    // Inline the content after the opening tag
                    result.append(formatContent(content))
                } else {
                    // Newline for regular text content
                    result.append(indentStep.repeat(currentIndent)).append(formatContent(content)).append("\n")
                }
            }
        }
    }

    return result.toString().trim() // Trim to remove extra leading/trailing spaces or newlines
}

// Helper function to format content with newlines and truncate on token boundaries
private fun formatContent(content: String): String {
    if (content.length <= 100) return content
    val words = content.split(Regex("\\s+")) // Split into tokens (words)
    val builder = StringBuilder()
    var lineLength = 0

    for (word in words) {
        if (lineLength + word.length + 1 > 100) {
            builder.append("\n")
            lineLength = 0
        }
        if (lineLength > 0) builder.append(" ")
        builder.append(word)
        lineLength += word.length + 1
    }

    return builder.toString()
}
