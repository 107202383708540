package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.client.api.client.Show
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.detail
import com.siriusxm.pia.components.detailGrid
import com.siriusxm.pia.components.spinner
import com.siriusxm.pia.utils.encodeURIComponent
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

/**
 * Renders a view for navigating and working with an episodes content links.
 */
@Composable
fun showContentLinks(showId: String) {
    var show by remember { mutableStateOf<Show?>(null) }
    LaunchedEffect(showId) {
        Application.api.query {
            show(showId) {
                id
                configuration {
                    active
                    linkedEntities
                }
            }
        }.show.let {
            show = it
        }
    }

    if (show == null) {
        spinner()
        return
    }

   if (show != null)  {
       box({
           title = "Content Links"
           paddedContent = false

           if (Application.viewer.contentEditor) {
               action("Edit", true) {
                   Application.navigation.navigate(
                       "podcasts/contentLinks?action=edit&showId=${show!!.id}&return=${
                           encodeURIComponent(
                               "podcasts/show/${show!!.id}/configuration"
                           )
                       }"
                   )
               }
           }
       }) {
           box{
               detailGrid {
                   show?.configuration?.let { showConfig ->
                       if (showConfig.linkedEntities.isNullOrEmpty()) {
                           detail("No Linked Entities") {}
                       } else {
                           detail("Linked Entities") {
                               showConfig.linkedEntities?.forEach {
                                   Div {
                                       Text("${it}")
                                   }
                               }

                           }
                       }

                   }
               }
           }
       }
   }
}