package com.siriusxm.content.management.console.applications.transcription

import androidx.compose.runtime.*
import com.siriusxm.pia.client.api.client.Show
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.transcription.*
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.utils.prettyPrint
import com.siriusxm.pia.utils.toLocalDateTimeString
import com.siriusxm.pia.views.podcasts.showSummary
import kotlinx.browser.localStorage
import kotlinx.coroutines.CancellationException
import kotlinx.datetime.Instant
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromJsonElement
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.get

/**
 * The main transcription page.
 */

@Composable
fun viewTranscription(typedEntityId: String, returnPath: String? = null) {

    var transcription by remember { mutableStateOf(emptyList<TranscriptionDetailSummary>()) }
    var entity by remember { mutableStateOf<AtlasEpisodeEntity?>(null) }
    var show by remember { mutableStateOf<Show?>(null) }
    var jobs by remember { mutableStateOf<PaginatedTranscriptionJobs?>(null) }
    var loading by mutableStateOf(false)

    LaunchedEffect(typedEntityId) {
        try {
            loading = true

            val transcriptionResponse = Transcriptions.api.getPreferredVersion(typedEntityId)

            entity = Transcriptions.aggregator.fetchEntityById(typedEntityId).firstOrNull()?.let {
                val json = Json { ignoreUnknownKeys = true }
                json.decodeFromJsonElement<AtlasEpisodeEntity>(it)
            }

            show = entity?.show?.let {
                try {
                    Transcriptions.podcastApi.query {
                        catalogShow(it) {
                            showSummary()
                        }
                    }.catalogShow
                } catch (e: Exception) {
                    console.log("Issue trying to look up id for show: $it")
                    null
                }
            }

            transcription = listOf(transcriptionResponse)
            jobs = Transcriptions.api.getJobsForEntityId(typedEntityId)
        } catch (e: CancellationException) {
            // ignored
            console.log("Canceled exception: $e")
        } catch (t: Throwable) {
            console.log("Got an exception: $t")
            transcription = emptyList()
        } finally {
            loading = false
        }
    }

    box({
        paddedContent = true
        header({
            title = "Transcription - $typedEntityId"
        }) {
        }
    }) {
        tabView {
            tab("Preferred Version") {
                resultsTab(typedEntityId, loading, transcription, entity, show, jobs)
            }

            if (Transcriptions.viewer.transcriptionAdmin) {
                tab("Edit") {
                    val baseUrl = Transcriptions.editorBaseUrl
                    if (baseUrl != null) {
                        val episodeTitle = entity?.name ?: ""
                        val url = entity?.media?.firstOrNull()?.url ?: ""
                        val image = null // TODO: find the image in the entity
                        val iframeUrl = determineIFrameUrl(baseUrl, typedEntityId, episodeTitle, url, image)

                        box("Transcription", {}) {
                            Iframe(attrs = {
                                attr("src", iframeUrl)
                                attr("width", "100%")
                                attr("height", "540")
                                attr("scrolling", "no")
                                attr("frameborder", "0")
                            })
                        }
                    }
                }
            }
        }
    }

    returnPath?.let {
        A(returnPath) {
            Text("Back")
        }
    }
}

@Composable
private fun resultsTab(typedEntityId: String, loading: Boolean, searchResults: List<TranscriptionDetailSummary>,
                       entity: AtlasEpisodeEntity?, show: Show?, jobs: PaginatedTranscriptionJobs?) {
    box("Transcription Entity") {
        detailGrid {
            detail("TypedEntityId", typedEntityId)
            if (!loading) {
                if (show != null) {
                    detail("Show") {
                        Div {
                            A(href = "#podcasts/show/" + show.id) {
                                Text("${show.title} ${entity?.show?.let { "- $it"}}")
                            }
                        }
                    }
                }
                detail("Episode Type", entity?.type ?: "")
                detail("Episode Name", entity?.name ?: "")
                detail("Episode Description", entity?.description ?: "")
            }
        }
    }
    if (loading) {
        Div {
            Text("Loading...")
        }
    }

    box("Transcription Details") {
        table<TranscriptionFormatSummary> {
            val versions = transcriptionFormatSummaries(searchResults, entity)

            items(versions)

            column {
                title = "TranscriptionId"
                content { summary ->
                    Div {
                        Text(summary.transcriptionId)
                    }
                }
            }

            column {
                title = "Version Number"
                content { summary ->
                    Text("${summary.versionNumber}")
                }
            }

            column {
                title = "Type"

                content { summary ->
                    Div {
                        Text("${summary.format}")
                    }
                }
            }

            column {
                title = "Url"

                content { summary ->
                    Div {
                        A(href = summary.url, { target(ATarget.Blank) }) {
                            Text(summary.url)
                        }
                    }
                }
            }

            column {
                title = "Active"

                content { summary ->
                    Div {
                        Text("${summary.active}")
                    }
                }
            }

            column {
                title = "Preferred Version"

                content { summary ->
                    Div {
                        Text("${summary.preferredVersion}")
                    }
                }
            }

            column {
                title = "Updated"

                content { summary ->

                    val instant = summary.updated?.let { Instant.parse(it) }

                    Div {
                        Text(instant?.toLocalDateTimeString() ?: "")
                    }
                }
            }
        }
    }

    if (jobs != null && jobs.jobs.isNotEmpty()) {
        box("Jobs") {
            table<TranscriptionJob> {
                items(jobs.jobs)

                column {
                    title = "JobId"
                    content { summary ->
                        Div {
                            Text(summary.jobId)
                        }
                    }
                }
                column {
                    title = "Fidelity"
                    content { summary ->
                        Div {
                            Text(summary.fidelity.toString())
                        }
                    }
                }
                column {
                    title = "Status"
                    content { summary ->
                        Div {
                            Text(summary.status?.name ?: "")
                        }

                        if (Transcriptions.viewer.transcriptionAdmin) {
                            if (canBeRetried(summary)) {
                                button("Retry") {
                                    val currentPath = Transcriptions.navigation.path
                                    Transcriptions.navigation.navigate(
                                        "transcriptions/job-retry/${
                                            encodeURIComponent(
                                                summary.jobId
                                            )
                                        }?action=view&return=${encodeURIComponent(currentPath)}"
                                    )
                                }
                            }
                        }

                    }
                }
                column {
                    title = "StatusDetail"
                    content { summary ->
                        Div {
                            Text(summary.statusDetail ?: "")
                        }
                    }
                }
                column {
                    title = "Start"
                    content { summary ->
                        Div {
                            Text(summary.start ?: "")
                        }
                    }
                }
                column {
                    title = "End"
                    content { summary ->
                        Div {
                            Text(summary.end ?: "-")
                        }
                    }
                }
                column {
                    title = "Provider"
                    content { summary ->
                        Div {
                            Text(summary.provider?.id ?: "-")
                        }
                    }
                }
                column {
                    title = "Provider Data"
                    content { summary ->
                        Div {
                            Text(summary.providerData?.data ?: "-")
                        }
                    }
                }
                column {
                    title = "Vocabulary Dictionaries"

                    content { summary ->
                        Div {
                            summary.request.customVocabularyIds?.forEachIndexed { index, item ->
                                if (index != 0) {
                                    Text(", ")
                                }
                                A("/#transcriptions/vocabulary/${encodeURIComponent(item)}?action=view&return=${encodeURIComponent("/#transcription/$typedEntityId?action=view") }") {
                                    Text(item)
                                }
                            }
                        }
                    }
                }
                column {
                    title = "Request"
                    content { summary ->
                        Div {
                            Pre {
                                Text(summary.request.let { Json.encodeToString(TranscriptionRequest.serializer(), it) }.prettyPrint())
                            }
                        }
                    }
                }
            }
        }
    }
}

private fun determineIFrameUrl(baseUrl: String?, typedEntityId: String, episodeTitle: String, audioUrl: String,
                               image: String? = null): String {
    val cognitoPrefix = "sxm"
    val token = localStorage["${cognitoPrefix}_access_token"]
    val typedEntityIdPart = encodeURIComponent(typedEntityId)

    val artworkUrl = image?.let { encodeURIComponent(it) }
    val artworkPart = artworkUrl?.let { "&artwork=$artworkUrl"} ?: "&hideCover=true"

    val encodedEpisodeTitle = encodeURIComponent(episodeTitle)

    return "$baseUrl/$typedEntityIdPart/editor?token=$token&audio=$audioUrl&title=$encodedEpisodeTitle$artworkPart&chromeless=true&theme=stitcher"
}

private fun transcriptionFormatSummaries(searchResults: List<TranscriptionDetailSummary>, entity: AtlasEpisodeEntity?): MutableList<TranscriptionFormatSummary> {
    val versions = mutableListOf<TranscriptionFormatSummary>()

    searchResults.forEach { result ->

        result.preferredVersion?.let {
            it.formats.forEach { f ->
                versions.add(
                    TranscriptionFormatSummary(
                        it.typedEntityId, it.transcriptionId, it.versionNumber,
                        f.format, f.url, it.updated, it.inactive != true, true,
                        episodeName = entity?.name, episodeDescription = entity?.description, episodeType = entity?.type,
                        showTypedEntityId = entity?.show
                    )
                )
            }
        }

        result.details?.forEach {
            it.versions?.forEach { v ->

                v.formats.forEach { f ->
                    val summary = TranscriptionFormatSummary(
                        v.typedEntityId, v.transcriptionId, v.versionNumber,
                        f.format, f.url, v.updated, v.inactive != true, false,
                        episodeName = entity?.name, episodeDescription = entity?.description, episodeType = entity?.type,
                        showTypedEntityId = entity?.show
                    )

                    if (!(result.preferredVersion?.equals(v) == true)) {
                        versions.add(summary)
                    }
                }
            }
        }
    }
    return versions
}
