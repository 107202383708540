package com.siriusxm.pia.components

import androidx.compose.runtime.Composable
import com.siriusxm.pia.SXMUI
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Text

object BoxStyles : StyleSheet() {
    val box by style {
        border(0.px, LineStyle.Solid, SXMUI.defaultDivider.value())
        borderWidth(0.px, 1.px, 1.px, 1.px)

        marginBottom(20.px)

        boxShadow("0px 1px 1px rgba(0,0,0,.2)")
    }

    val header by style {
        display(DisplayStyle.Flex)
        alignItems(AlignItems.Stretch)
        justifyContent(JustifyContent.SpaceBetween)
        backgroundColor(SXMUI.containerHeaderBackgroundColor.value())
        padding(1.cssRem)

        "h2" style {
            margin(0.px)
            fontSize(1.2.cssRem)
            lineHeight((1.5).cssRem)
            marginRight(1.cssRem)
        }

        "p" style {
            margin(0.px)
        }

        border(0.px, LineStyle.Solid, SXMUI.defaultDivider.value())
        borderWidth(0.px, 0.px, 1.px, 0.px)
    }

    val headerContent by style {
        flexGrow(2)
    }

    val headerInstruction by style {
        marginTop(.5.em)
        fontSize(.8.em)
    }

    val headerActions by style {
        textAlign("right")
    }

    val content by style {
        backgroundColor(SXMUI.containerContentBackgroundColor.value())
        padding(1.cssRem)
    }

    val noPaddingContent by style {

    }

    val boxMessage by style {
        textAlign("center")
        fontSize(SXMUI.smallFontSize.value())
        color(SXMUI.subtleTextColor.value())
    }
}

class BoxHeaderConfig {
    var title: String? = null
    var instruction: String? = null
    internal var content: (@Composable () -> Unit)? = null
    internal var actionContent: (@Composable () -> Unit)? = null

    internal val actions = mutableListOf<ButtonConfiguration>()

    fun actionContent(block: @Composable () -> Unit) {
        actionContent = block
    }

    fun content(block: @Composable () -> Unit) {
        content = block
    }

    fun action(block: ButtonConfiguration) {
        actions += block
        actionContent = {
            actions.forEach {
                button(it)
            }
        }
    }

    fun action(block: ButtonConfiguration.() -> Unit) {
        action(ButtonConfiguration().apply(block))
    }
}

class BoxConfig {
    var title: String?
        get() = headerConfig.title
        set(value) {
            headerConfig.title = value
        }

    var paddedContent: Boolean = true
    internal val headerConfig = BoxHeaderConfig()

    fun header(builder: (BoxHeaderConfig.() -> Unit)? = null, block: (@Composable () -> Unit)? = null) {
        builder?.let {
            headerConfig.it()
        }
        block?.let {
            headerConfig.content(it)
        }
    }


    fun action(block: ButtonConfiguration.() -> Unit) {
        headerConfig.action(block)
    }

    fun action(block: ButtonConfiguration) {
        headerConfig.action(block)
    }

    fun action(title: String, primary: Boolean = false, impl: suspend () -> Unit) {
        headerConfig.action {
            this.title = title
            this.primary = primary
            this.action(impl)
        }
    }
}


/**
 * Just a simple box component.
 */
@Composable
fun box(
    title: String,
    config: BoxConfig.() -> Unit = {},
    content: @Composable (() -> Unit)? = null
) {
    box({
        this.title = title
        config()
    }, content)
}

@Composable
fun box(
    builder: BoxConfig.() -> Unit = {},
    content: @Composable (() -> Unit)? = null
) {
    val config = BoxConfig().apply(builder)

    Div({
        classes(BoxStyles.box)
    }) {
        boxHeader(config.headerConfig)
        content?.let {
            boxContent(config.paddedContent) {
                it()
            }
        }
    }
}

@Composable
private fun boxHeader(config: BoxHeaderConfig) {
    if (config.title == null && config.actions.isEmpty() && config.content == null && config.instruction == null &&
        config.actionContent == null
    ) {
        return // nothing to render
    }

    Div({
        classes(BoxStyles.header)
    }) {
        Div({ classes(BoxStyles.headerContent) }) {
            config.title?.let {
                H2 {
                    Text(it)
                }
            }

            config.instruction?.let {
                Div({ classes(BoxStyles.headerInstruction) }) {
                    Text(it)
                }
            }

            config.content?.let {
                Div {
                    it()
                }
            }
        }
        Div({ classes(BoxStyles.headerActions) }) {
            config.actionContent?.let {
                it()
            }
        }


    }
}

@Composable
fun boxContent(padded: Boolean = true, content: @Composable () -> Unit = {}) {
    Div({
        if (padded) {
            classes(BoxStyles.content)
        } else {
            classes(BoxStyles.noPaddingContent)
        }
    }) {
        content()
    }
}

/**
 * Render a message inside of a box.
 */
@Composable
fun boxMessage(message: String) {
    boxContent {
        Div({ classes(BoxStyles.boxMessage) }) {
            Text(message)
        }
    }
}

@Composable
fun boxSpinner(message: String? = null) {
    boxContent {
        if (message != null) {
            Div({ classes(BoxStyles.boxMessage) }) {
                Div {
                    spinner()
                }
                Div {
                    Text(message)
                }
            }
        } else {
            spinner()
        }
    }
}

@Composable
fun <T> boxTable(title: String? = null, config: TableBuilder<T>.() -> Unit) {
    box({
        this.title = title
        paddedContent = false
    }) {
        table(config)
    }
}