package com.siriusxm.pia.views.mddb

import com.siriusxm.pia.EntityAugmenter
import contentingestion.unifiedmodel.EntityType

open class MddbAugmenter(val api: MddbAPI) : EntityAugmenter {
    override val name: String = "MDDB"
    override fun canAugment(entityType: EntityType): Boolean {
        // TODO: replace with a lookup of the types produced by MDDB, for now, hardcode them
        return listOf(
                EntityType.CATEGORY,
                EntityType.CHANNEL_LINEAR,
                EntityType.CHANNEL_XTRA,
                EntityType.CUT_AUDIO,
                EntityType.CUT_LINEAR,
                EntityType.CUT_VIDEO,
                EntityType.EPISODE_AUDIO,
                EntityType.EPISODE_BASE,
                EntityType.EPISODE_VIDEO,
                EntityType.LINEUP,
                EntityType.ROYALTY_INFO_REPORT,
                EntityType.SHOW,
                EntityType.XTRA_CLIP
            ).contains(entityType)
    }

    override suspend fun lookupEntityAugmentation(entityType: EntityType, entityId: String): String? {
        if (!canAugment(entityType)) return null

        return try {
            api.fetchRawEntity(entityId)
        } catch (e: Throwable) {
            null
        }
    }
}