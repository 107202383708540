package com.siriusxm.content.management.console.applications.transcription

import com.siriusxm.pia.IngestionAPI
import com.siriusxm.pia.Viewer
import com.siriusxm.pia.components.NotificationsConfig
import com.siriusxm.pia.rest.unifiedaggregator.UnifiedAggregatorClient
import com.siriusxm.pia.utils.HashNavigator

/**
 * A global context for the transcription application
 */
class TranscriptionContext(
    val api: TranscriptionAPI,
    val navigation: HashNavigator,
    val aggregator: UnifiedAggregatorClient,
    val podcastApi: IngestionAPI,
    /**
     * This is temporary until we can better modularize some of these things.
     */
    val notificationsGetter: () -> NotificationsConfig,
    val viewerGetter: () -> Viewer,
    val editorBaseUrl: String?
) {
    val notifications: NotificationsConfig get() = notificationsGetter()
    val viewer: Viewer get() = viewerGetter()
}

/**
 * A global variable to hold the Transcriptions context. Might be useful
 * to pass as a parameter instead, but works fine as is.
 */
lateinit var Transcriptions: TranscriptionContext