package com.siriusxm.pia.views.unifiedaggregator.backfill

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.navigate
import com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus
import com.siriusxm.pia.utils.toLocalDateTimeString
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.minutes

@Composable
fun backfills(aggregator: AggregatorService) {
    var backfills by remember { mutableStateOf<List<BackfillStatus>?>(null) }
    var region by remember { mutableStateOf<String?>(null) }
    var workload by remember { mutableStateOf<String?>(null) }

    LaunchedEffect("fills") {
        backfills = aggregator.api.backfills(Clock.System.now().minus(5.days).toString())
            .sortedBy { it.startTime ?: Instant.DISTANT_PAST }.reversed()
    }

    serviceContentView({
        title = "Content Backfill"

        action("New Job", false) {
            Application.navigate("aggregator/backfill/new")
        }
    }) {
        if (backfills == null) {
            spinner(size = Size.LARGE)
        } else {
            box({
                title = "Backfill Jobs"
                paddedContent = false
                header({
                    this.title = "Backfill Jobs"
                }) {
                    detailGrid {
                        detail("Region") {
                            Select({
                                onChange {
                                    region = it.value?.takeIf { it != "all" }
                                }
                            }) {
                                Option("all", {
                                    if (region == null) {
                                        selected()
                                    }
                                }) {
                                    Text("All")
                                }
                                listOf("us-east-2", "us-east-1", "us-west-2").forEach {
                                    Option(it, {
                                        if (region == it) selected()
                                    }) {
                                        Text(it)
                                    }
                                }
                            }
                        }
                        detail("Workload") {
                            Select({
                                onChange {
                                    workload = it.value?.takeIf { it != "all" }
                                }
                            }) {
                                Option("all", {
                                    if (workload == null) selected()
                                }) { Text("All") }

                                backfills?.mapNotNull { it.workload }?.distinct()?.forEach {
                                    Option(it, {
                                        if (workload == it) selected()
                                    }) {
                                        Text(it)
                                    }
                                }
                            }
                        }
                    }
                }
            }) {
                table<BackfillStatus> {
                    items(backfills.orEmpty().filter {
                        region == null || it.region == region
                    }.filter {
                        workload == null || it.workload == workload
                    })

                    column {
                        width = 40.px
                        content {
                            when (it.status) {
                                "COMPLETE" -> {
                                    icon("check_circle") {
                                        style { color(Color.green) }
                                    }
                                }

                                "FAILED" -> {
                                    icon("warning") {
                                        style { color(Color.red) }
                                    }
                                }

                                "CANCELLED" -> {
                                    icon("cancel") {
                                        style { color(Color.gray) }
                                    }
                                }

                                "ACTIVE" -> {
                                    icon("timelapse")
                                }
                            }
                        }
                    }

                    column {
                        title = "JobId"
                        width = 12.em
                        content {
                            Text(it.jobId)
                        }
                    }

                    column {
                        title = "Date"
                        width = 12.em
                        content {
                            Text(it.startTime?.toLocalDateTimeString().orEmpty())
                        }
                    }

                    column {
                        title = "Target"
                        content {
                            it.targetArn?.let { arn ->
                                Div({
                                    style {
                                        lineHeight(0.8.cssRem)
                                        padding(5.px, 0.px)
                                    }
                                }) {
                                    Div({
                                        style {
                                            fontSize(0.8.em)
                                        }
                                    }) {
                                        Text(it.targetType.orEmpty())
                                    }
                                    Div({
                                        style {
                                            fontSize(1.cssRem)
                                        }
                                    }) {
                                        A(href = "#aggregator/backfill/${it.jobId}") {
                                            Text(it.targetName.orEmpty())
                                        }
                                    }
                                }
                            }
                        }
                    }

                    column {
                        title = "Data Timestamp"
                        content {
                            Text(it.timestamp?.let { Instant.parse(it) }?.toLocalDateTimeString().orEmpty())
                        }
                    }

                    column {
                        title = "Region"
                        content {
                            Text(it.targetArn?.split(":")?.get(3).orEmpty())
                        }
                    }

                    column {
                        title = "Count"
                        content {
                            Text(it.count.toLocaleString())
                        }
                    }
                    column {
                        title = "Duration"
                        content {
                            val elapsed = it.elapsedTime()
                            val hours = elapsed.inWholeHours
                            val minutes = (elapsed - hours.hours).inWholeMinutes
                            val seconds = (elapsed - hours.hours - minutes.minutes).inWholeSeconds

                            val durationStr = buildList<String> {
                                if (hours > 0) {
                                    add(hours.toString())
                                }
                                add(minutes.toString().padStart(2, '0'))
                                add(seconds.toString().padStart(2, '0'))
                            }.joinToString(":")
                            Text(durationStr)
                        }
                    }
                }
            }

        }
    }
}


val BackfillStatus.targetName: String?
    get() {
        val arnSplits = targetArn?.split(":") ?: return null
        return if (arnSplits[2] == "logs") {
            targetArn!!.substringAfter("log-group:").substringBefore(':')
        } else {
            targetArn!!.substringAfterLast("/")
        }
    }

val BackfillStatus.targetType: String?
    get() {
        val arnSplits = targetArn?.split(":") ?: return null
        return when (val targetType = arnSplits[2]) {
            "events" -> "Event Bus"
            "logs" -> "Log Group"
            else -> targetType
        }
    }

val BackfillStatus.region: String?
    get() {
        return targetArn?.split(":")?.get(3)
    }


val BackfillStatus.workload: String?
    get() {
        return targetName?.let {
            if (it.startsWith("app-")) {
                it.removePrefix("app-").split("-").let {
                    "${it.first()}-${it.get(1)}"
                }
            } else null
        }
    }