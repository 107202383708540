package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.client.api.ShowsRequest
import com.siriusxm.pia.client.api.client.Show
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.spinner
import com.siriusxm.pia.components.table
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancel
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

enum class ShowType {
    Free,
    Freemium,
    Premium
}

val Show.showType: ShowType
    get() {
        return sources.orEmpty().let {
            if (it.find { it.bundles.isNullOrEmpty() } != null) {
                if (it.find { it.bundles?.isNotEmpty() == true } != null) {
                    ShowType.Freemium
                } else {
                    ShowType.Free
                }
            } else {
                ShowType.Premium
            }
        }
    }

private const val stitcherShowUrlPrefix = "https://www.stitcher.com/show/"
private val emailRegex = "^[\\w.-]+@([\\w-]+\\.)+[\\w-]{2,4}\$".toRegex()


/**
 * Renders the main application dashboard.
 */
@Composable
fun podcastsDashboard() {
    var searchText by remember { mutableStateOf("") }
    var searchResults by remember { mutableStateOf(emptyList<Show>()) }
    val coroutineScope = rememberCoroutineScope()
    var searchJob by mutableStateOf<Job?>(null)
    var searching by mutableStateOf(false)
    var showStats by remember { mutableStateOf<AuditShowResponse?>(null) }

    LaunchedEffect("status") {
        showStats = Application.ingestionAuditAPI.auditShows("pmc")
    }

    suspend fun search(text: String) {
        try {
            searching = true
            val showId = text.toLongOrNull()
            if (showId != null) {
                val show = Application.api.query {
                    contentService {
                        show(showId.toString()) {
                            show {
                                showSummary()
                            }
                        }
                    }
                }.contentService.show.show
                searchResults = listOfNotNull(show)
            } else if (text.startsWith(stitcherShowUrlPrefix)) {
                val slug = text.removePrefix(stitcherShowUrlPrefix).substringBeforeLast("/")
                    .substringBeforeLast("?").trim()
                if (slug.isNotBlank()) {
                    Application.api.query {
                        showBySlug(slug) {
                            showSummary()
                        }
                    }.showBySlug.also {
                        searchResults = listOf(it)
                    }
                }
            } else if (text.startsWith("http")) {
                val show = Application.api.query {
                    feed(text) {
                        show {
                            showSummary()
                        }
                    }
                }.feed.show
                searchResults = listOfNotNull(show)
            } else if (text.startsWith("PC:")) {
                Application.api.query {
                    catalogShow(text) {
                        showSummary()
                    }
                }.catalogShow.also {
                    searchResults = listOf(it)
                }
            } else if (emailRegex.matches(text)) {
                searchResults = Application.api.query {
                    shows(ShowsRequest(owner = text)) {
                        shows {
                            showSummary()
                        }
                    }
                }.shows.shows
            } else {
                searchResults = Application.api.query {
                    shows(ShowsRequest(query = text, includePrivate = true)) {
                        shows {
                            showSummary()
                        }
                    }
                }.shows.shows
            }
        } catch (e: CancellationException) {
            // ignored
        } catch (t: Throwable) {
            searchResults = emptyList()
        } finally {
            searching = false
        }
    }

    box({
        paddedContent = searchResults.isEmpty() || searching
        header({
            title = "Shows"
        }) {
            Div({
                style {
                    width(100.percent)
                }
            }) {
                Div {
                    Input(InputType.Text, attrs = {
                        defaultValue(searchText)
                        placeholder("Enter a show id, a show title, or a show feed URL")
                        onInput { event ->
                            searchText = event.value

                            searchJob?.cancel("Starting new search")
                            searchJob = null
                            if (event.value.length >= 4) {
                                searchJob = coroutineScope.launch {
                                    search(event.value)
                                }
                            }

                        }

                        style {
                            width(100.percent)
                        }
                    })
                }
            }
        }
    }) {
        if(showStats == null) {
            spinner()
        } else {
            Div {
                Text("Total shows: ${showStats?.totalShows}")
            }
            Div {
                Text("Stitcher Only shows: ${showStats?.stitcherOnlyShows}")
            }
            Div {
                Text("Active shows: ${showStats?.activeShows}")
            }
            Div {
                Text("Inactive shows: ${showStats?.inactiveShows}")
            }
        }
        if (searching) {
            spinner()
        } else if (searchResults.isEmpty()) {
            if (searchText.length >= 4) {
                Text("No shows have been found")
            } else {
                Text("Enter search text to find shows...")
            }
        } else {
            table<Show> {
                items(searchResults)

                column {
                    title = "Show Title"
                    content { show ->
                        Div({
                            style {
                                display(DisplayStyle.Flex)
                            }
                        }) {
                            Div({
                                style {
                                    width(64.px)
                                    height(64.px)
                                    marginRight(5.px)
                                    overflow("hidden")
                                }
                            }) {
                                show.images?.firstOrNull()?.sizes?.firstOrNull()?.let {
                                    Img(it) {
                                        style {
                                            maxWidth(100.percent)
                                        }
                                    }
                                }
                            }
                            Div {
                                A(href = "/#podcasts/show/${show.id}") {
                                    Text(show.titleOrUnknown())
                                }
                                Div {
                                    Text(show.showType.name)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
