package com.siriusxm.pia.views.channelguide

import androidx.compose.runtime.Composable
import com.siriusxm.pia.ApplicationContext
import com.siriusxm.pia.rest.unifiedaggregator.FormatParams
import com.siriusxm.pia.rest.unifiedaggregator.ImageRenderingHelper
import com.siriusxm.pia.rest.unifiedaggregator.ResizeParams
import contentingestion.unifiedmodel.Image
import contentingestion.unifiedmodel.ImageAspectRatio
import contentingestion.unifiedmodel.ImagePurpose
import contentingestion.unifiedmodel.ImagePurposeMap
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img

/**
 * Render
 */
@Composable
fun <T : CSSUnit> defaultImage(
    context: ApplicationContext,
    image: Image,
    resize: ResizeParams,
    width: CSSNumericValue<T>
) {
    val imageUrl = ImageRenderingHelper.getImageUrl(
        context.configuration.atlasImageServerBaseUrl,
        image.url,
        resize,
        FormatParams("jpeg")
    )

    Div({
        style {
            width(width)
            borderRadius(5.px)
            overflow("hidden")
        }
    }) {
        Img(src = imageUrl, attrs = {
            style {
                width(100.percent)
                display(DisplayStyle.Block)
            }
        })
    }
}

/**
 * Render an image with default styling.
 */
@Composable
fun <T : CSSUnit> defaultImage(
    context: ApplicationContext,
    image: Image,
    aspect: ImageAspectRatio,
    width: CSSNumericValue<T>
) {
    val size = when (aspect) {
        ImageAspectRatio.ASPECT_1X1 -> ResizeParams(600, 600)
        ImageAspectRatio.ASPECT_16X9 -> ResizeParams(800, 450)
        ImageAspectRatio.ASPECT_5X4 -> ResizeParams(750, 600)
        else -> null
    }
    if (size != null) {
        defaultImage(context, image, size, width)
    }
}

/**
 * Render an image with default styling.
 */
@Composable
fun <T : CSSUnit> defaultImage(
    context: ApplicationContext,
    images: ImagePurposeMap?,
    aspect: ImageAspectRatio,
    width: CSSNumericValue<T>
) {
    val image = images?.get(ImagePurpose.TILE)?.let {
        it[aspect]
    }?.default

    if (image != null) {
        defaultImage(context, image, aspect, width)
    }
}