package com.siriusxm.content.management.console.applications.transcription

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.transcription.TranscriptionDetailSummary
import com.siriusxm.pia.rest.transcription.TranscriptionFormatSummary
import com.siriusxm.pia.utils.toLocalDateTimeString
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancel
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Text

/**
 * The main transcription page.
 */

@Composable
fun transcriptionsPage(returnPath: String? = null) {

    var searchText by remember { mutableStateOf("") }
    var searchResults by remember { mutableStateOf(emptyList<TranscriptionDetailSummary>()) }
    val coroutineScope = rememberCoroutineScope()
    var searching by mutableStateOf(false)
    var searchJob by mutableStateOf<Job?>(null)

    suspend fun search(text: String) {
        try {
            searching = true

            val preferredVersion = Transcriptions.api.getPreferredVersion(text)
            searchResults = listOfNotNull(preferredVersion)
        } catch (e: CancellationException) {
            // ignored
        } catch (t: Throwable) {
            console.log("Got an exception: $t")
            searchResults = emptyList()
        } finally {
            searching = false
        }
    }

    box({
        paddedContent = searchResults.isEmpty() || searching
        header({
            title = "Transcriptions"
        }) {
            Div({
                style {
                    width(100.percent)
                }
            }) {
                Div {
                    Input(InputType.Text, attrs = {
                        defaultValue(searchText)
                        placeholder("Enter a typedEntityId to find a transcription")
                        onInput { event ->
                            searchText = event.value

                            searchJob?.cancel("Starting new search")
                            searchJob = null
                            if (event.value.length >= 4) {
                                searchJob = coroutineScope.launch {
                                    search(event.value)
                                }
                            }
                        }

                        style {
                            width(100.percent)
                        }
                    })
                }
            }
        }
    }) {
        if (searching) {
            spinner()
        } else if (searchResults.isEmpty()) {
            if (searchText.length >= 4) {
                Text("No shows have been found")
            } else {
                Text("Enter search text to find shows...")
            }
        } else {

            tabView {
                tab("Results") {
                    resultsTab(searchResults)
                }
            }
        }
    }
}

@Composable
private fun resultsTab(searchResults: List<TranscriptionDetailSummary>) {
    table<TranscriptionFormatSummary> {
        val versions = transcriptionFormatSummaries(searchResults)

        items(versions)

        column {
            title = "TypedEntityId"
            content { summary ->
                Div {
                    val typedEntityId = summary.typedEntityId
                    A(href = transcriptionIdLink(typedEntityId, "")) {
                        Text(typedEntityId)
                    }
                }
            }
        }
        column {
            title = "TranscriptionId"
            content { summary ->
                Div {
                    Text(summary.transcriptionId)
                }
            }
        }

        column {
            title = "Version Number"
            content { summary ->
                Text("${summary.versionNumber}")
            }
        }


        column {
            title = "Type"

            content { summary ->
                Div {
                    Text("${summary.format}")
                }
            }
        }

        column {
            title = "Url"

            content { summary ->
                Div {
                    A(href = summary.url, { target(ATarget.Blank) }) {
                        Text(summary.url)
                    }
                }
            }
        }

        column {
            title = "Active"

            content { summary ->
                Div {
                    Text("${summary.active}")
                }
            }
        }

        column {
            title = "Preferred Version"

            content { summary ->
                Div {
                    Text("${summary.preferredVersion}")
                }
            }
        }

        column {
            title = "Updated"

            content { summary ->

                val instant = summary.updated?.let {
                    try {
                        Instant.parse(it)
                    } catch (e: Exception) {
                        null
                    }
                }

                Div {
                    Text(instant?.toLocalDateTimeString() ?: "")
                }
            }
        }
    }
}

private fun transcriptionFormatSummaries(searchResults: List<TranscriptionDetailSummary>): MutableList<TranscriptionFormatSummary> {
    val versions = mutableListOf<TranscriptionFormatSummary>()

    searchResults.forEach { result ->
        result.preferredVersion?.let {
            it.formats.forEach { f ->
                versions.add(
                    TranscriptionFormatSummary(
                        it.typedEntityId, it.transcriptionId, it.versionNumber,
                        f.format, f.url, it.updated, it.inactive != true, true
                    )
                )
            }
        }

        result.details?.forEach {
            it.versions?.forEach { v ->

                v.formats.forEach { f ->
                    val summary = TranscriptionFormatSummary(
                        v.typedEntityId, v.transcriptionId, v.versionNumber,
                        f.format, f.url, v.updated, v.inactive != true, false
                    )

                    if (!(result.preferredVersion?.equals(v) == true)) {
                        versions.add(summary)
                    }
                }
            }
        }
    }
    return versions
}

@Composable
fun status() {
    var status by remember { mutableStateOf<String?>(null) }

    LaunchedEffect("status") {
        Transcriptions.api.status().let {
            status = it
        }
    }

    if (status != null) {
        entityView({
            title = "Transcription Service Status"
            subTitle = status
        }) {
            box {
                Div({
                    style {
                        display(DisplayStyle.Flex)
                        gap(10.px)
                    }
                }) {
                    detailGrid {
                        detail("Details") {
                            Text("Details will go here")
                        }
                    }
                }
            }
        }
    } else {
        spinner()
    }
}
