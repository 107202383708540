package com.siriusxm.pia.views.sports

import androidx.compose.runtime.Composable
import com.siriusxm.pia.ApplicationContext
import com.siriusxm.pia.ConsoleApplication
import com.siriusxm.pia.rest.unifiedaggregator.UnifiedAggregatorClient
import com.siriusxm.pia.utils.Route
import com.siriusxm.unifiedcontent.sports.SportsClient
import de.jensklingenberg.ktorfit.Ktorfit
import io.ktor.client.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.json.Json

/**
 * The sports application
 */
class SportsApplication : ConsoleApplication {
    internal lateinit var aggregator: UnifiedAggregatorClient
    internal lateinit var context: ApplicationContext
    internal lateinit var client: SportsClient

    override fun initialize(context: ApplicationContext) {
        this.context = context

        val httpClient = HttpClient {
            install(ContentNegotiation) {
                json(Json { isLenient = true; ignoreUnknownKeys = true })
            }
            expectSuccess = true
            install(Auth) {
                bearer {
                    loadTokens {
                        BearerTokens(context.accessToken!!, context.refreshToken!!)
                    }
                }
            }
        }

        client = SportsClient(context.configuration.sportsApiUrl!!) {
            BearerTokens(context.accessToken!!, context.refreshToken!!)
        }

        this.aggregator = Ktorfit.Builder()
            .baseUrl(context.configuration.unifiedAggregatorApiUrl)
            .httpClient(httpClient)
            .build().create()
    }

    @Composable
    override fun route(route: Route) {
        route.sportsDashboard(this)
    }

    /**
     * Navigate to the path (relative to the sports path, so don't start with "sports")
     */
    fun navigate(path: String) {
        context.navigate("sports/${path.removePrefix("sports/")}")
    }
}