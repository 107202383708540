package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.client.api.FeedInput
import com.siriusxm.pia.client.api.ShowUpdateInput
import com.siriusxm.pia.client.api.ValidateFeedsInput
import com.siriusxm.pia.client.api.client.Feed
import com.siriusxm.pia.client.api.client.Show
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.encodeURIComponent
import io.ktor.http.*
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.dom.*

class OperationResult(val type: MessageType, val renderer: @Composable () -> Unit)

fun List<OperationResult>.isOverridable() = !any { it.type == MessageType.ALERT }

class OperationProgress(val message: String)

/**
 * Edit or add a feed
 */
@Composable
fun editFeed(feedUrl: String?, showId: String? = null, returnPath: String? = null) {
    var url by remember { mutableStateOf(feedUrl) }
    var feed by remember { mutableStateOf<Feed?>(null) }
    var show by remember { mutableStateOf<Show?>(null) }

    var bundle by remember { mutableStateOf<String?>(null) }
    var active by remember { mutableStateOf<Boolean>(true) }

    var operationProgress by remember { mutableStateOf<List<OperationProgress>?>(null) }
    var operationResult by remember { mutableStateOf<List<OperationResult>>(emptyList()) }

    if (!Application.viewer.contentEditor) {
        return
    }

    LaunchedEffect(feedUrl) {
        if (feedUrl != null) {
            Application.api.query {
                feed(feedUrl) {
                    this.url
                    this.bundles {
                        id
                    }
                    this.active
                    show {
                        id
                        title
                        sources {
                            this.url
                            bundles {
                                id
                            }
                        }
                        csShow {
                            csId
                        }
                    }
                }
            }.feed.let {
                feed = it
                show = it.show
                bundle = it.bundles?.firstOrNull()?.id
                active = it.active
            }
        } else if (showId != null) {
            Application.api.query {
                show(showId) {
                    id
                    title
                    sources {
                        this.url
                        bundles {
                            id
                        }
                    }
                }
            }.show.let {
                show = it
            }
        }
    }

    LaunchedEffect(url, bundle, active) {
        operationResult = emptyList()
    }

    fun addResult(type: MessageType, renderer: @Composable () -> Unit) {
        operationResult = operationResult + OperationResult(type, renderer)
    }

    fun addResult(type: MessageType, text: String) {
        operationResult = operationResult + OperationResult(type) {
            Text(text)
        }
    }

    fun addProgress(message: String) {
        operationProgress = operationProgress.orEmpty() + OperationProgress(message)
    }

    /**
     * Validate a feed, and update the operation progress
     */
    suspend fun validate() {
        operationProgress = emptyList()
        operationResult = emptyList()

        addProgress("Checking input")

        val inputUrl = url.orEmpty().trim()
        val parsedUrl = Url(inputUrl)
        if (parsedUrl.toString() != inputUrl) {
            addResult(MessageType.ALERT, "This is an invalid URL: $parsedUrl $url")
        }

        addProgress("Checking for existing shows")

        // first, check that this feed isn't already associated with a show
        val existing = try {
            Application.api.query {
                feed(parsedUrl.toString()) {
                    this.url
                    this.show {
                        id
                        title
                    }
                    this.moves {
                        this.url
                        this.date
                    }
                }
            }.feed
        } catch (e: Throwable) {
            null
        }

        if (existing != null) {
            if (existing.show != null) {
                if (existing.url != url) {
                    operationResult = operationResult + OperationResult(MessageType.WARNING) {
                        Text("This feed was previously moved, associated with the show ")
                        A(href = "podcasts/show/${existing.show?.id}") {
                            Text(existing.show?.title.orEmpty())
                        }
                    }
                } else {
                    operationResult = operationResult + OperationResult(MessageType.ALERT) {
                        Text("This feed is already associated with the show ")
                        A(href = "podcasts/show/${existing.show?.id}") {
                            Text(existing.show?.title.orEmpty())
                        }
                    }
                }
                return
            } else {
                operationResult = operationResult + OperationResult(MessageType.WARNING) {
                    Text("This feed is in the system, but inactive.")
                }
                return
            }
        }

        addProgress("Validating and Matching")
        val result = Application.api.mutate {
            validateFeeds(ValidateFeedsInput(listOf(parsedUrl.toString()))) {
                this.issues {
                    this.id
                    this.element
                    this.type
                    this.note
                }
                matches {
                    confidence
                    show {
                        id
                        title

                        sources {
                            this.url
                        }
                    }
                }
            }
        }.validateFeeds.first()

        result.issues?.forEach { issue ->
            operationResult =
                operationResult + OperationResult(if (issue.type == "ERROR") MessageType.ALERT else MessageType.WARNING) {
                    Div {
                        Text(issue.note.orEmpty())
                    }
                }
        }

        result.matches?.filter {
            it.show.id != show?.id
        }?.filter { it.confidence >= 50 }?.forEach { match ->
            addResult(MessageType.WARNING) {
                Text("This feed appears to be very similar to the show ")
                A(href = "#podcasts/show/${match.show.id}") {
                    Text(match.show.title.orEmpty())
                }
            }
        }
    }

    fun navigateBack() {
        val path = returnPath ?: feedUrl?.let {
            "feeds/${encodeURIComponent(feedUrl)}"
        } ?: ""
        Application.navigation.navigate(path)
    }

    /**
     * Add or update the feed.
     */
    suspend fun addFeed() {
        val existing = try {
            Application.api.query {
                feed(url!!) {
                    this.url
                    show {
                        id
                    }
                    this.active
                }
            }.feed
        } catch (e: Throwable) {
            null
        }

        if (existing != null && (!existing.active || existing.url != url)) {
            if (show?.id == null) {
                Application.api.mutate {
                    addShow(FeedInput(url!!), ShowUpdateInput()) {
                        this.id
                    }
                }
            } else {
                Application.api.mutate {
                    feed(url!!) {
                        update(FeedInput(null, show?.id, bundle, active, active))
                    }
                }
            }
        } else {
            Application.api.mutate {
                addFeed(listOf(FeedInput(url, show?.id, bundle, active, active))) {
                    this.url
                    show {
                        id
                    }
                }
            }.addFeed.first().let {
                addProgress("Updating Show")
                waitForShowSourceUpdate(it.show?.id!!, url!!, true)
            }
        }
    }

    dialogView(if (feedUrl == null) "Add Feed" else "Edit Feed") {
        content {
            box {
                if (feedUrl != null && feed == null) {
                    spinner()
                    return@box
                }

                dialogField(
                    "URL"
                ) {
                    Input(InputType.Text) {
                        url?.let {
                            defaultValue(it)
                        }
                        onInput {
                            url = it.value.ifBlank { null }
                        }
                    }
                }

                if (feedUrl != null && feedUrl != url && show != null) {
                    messageBox("Changing the URL will update the show ${show?.title} to use this new URL.")
                }

                show?.let { show ->
                    dialogField("Show") {
                        A(href = "#podcasts/show/${show.id}") {
                            Text(show.titleOrUnknown())
                        }
                    }
                }

                if (feedUrl != null && feedUrl != feed?.url) {
                    dialogField("Resolves To") {
                        A(href = "#podcasts/feeds/${encodeURIComponent(feed?.url.orEmpty())}") {
                            Text(feed?.url.orEmpty())
                        }
                    }
                }

                dialogField("Type") {
                    Select({
                        onChange {
                            bundle = it.value?.takeIf { it != "free" } ?: ""
                        }
                    }) {
                        Option("free", {
                            if (bundle == null) selected()
                        }) {
                            Text("Free")
                        }
                        Option("restricted", {
                            if (bundle != null) selected()
                        }) {
                            Text("Restricted")
                        }
                    }
                }

                dialogField("State") {
                    radioGroup(if (active) "active" else "inactive") {
                        option("Active", "active")
                        option(
                            "Inactive", "inactive",
                            "Inactive feeds remain associated with a show but are fetched or included in results."
                        )

                        onChange { newValue ->
                            active = newValue == "active"
                        }
                    }
                }
            }

            if (!operationProgress.isNullOrEmpty()) {
                box {
                    spinner()

                    operationProgress?.forEach {
                        P {
                            Text(it.message)
                        }
                    }
                }
            }

            if (operationResult.isNotEmpty()) {
                messageBox(MessageType.ALERT) {
                    operationResult.forEach {
                        Div {
                            it.renderer()
                        }
                    }
                }
            }
        }


        action("Cancel") {
            navigateBack()
        }

        if (feedUrl == null) {
            action {
                title = "Add"
                if (operationResult.isNotEmpty()) {
                    if (operationResult.isOverridable()) {
                        title = "Add Anyway"
                    } else {
                        enabled = false
                    }
                } else if (url.isNullOrBlank()) {
                    enabled = false
                }
                primary = true
                showProgressOnAction = true
                action {
                    val ignoreWarnings = operationResult.isNotEmpty() && operationResult.isOverridable()
                    if (operationResult.isEmpty() || ignoreWarnings) {
                        validate()

                        if (operationResult.isEmpty() || ignoreWarnings) {
                            addProgress("Adding feed")
                            addFeed()

                            addProgress("Redirecting")
                            Application.navigation.navigate(returnPath ?: "feeds/${encodeURIComponent(url.orEmpty())}")
                        }
                    }
                    operationProgress = null
                }
            }
        } else {
            action {
                title = if (operationResult.isNotEmpty() && operationResult.isOverridable()) {
                    "Save Anyway"
                } else {
                    "Save"
                }
                primary = true
                showProgressOnAction = true

                action {
                    val ignoreWarnings = operationResult.isNotEmpty() && operationResult.isOverridable()
                    if (operationResult.isEmpty() || ignoreWarnings) {
                        if (!url.isNullOrBlank()) {
                            if (url != feed?.url) {
                                if (!ignoreWarnings) {
                                    validate()
                                }
                                operationProgress = null
                            }
                            if (operationResult.isEmpty() || ignoreWarnings) {
                                addProgress("Updating show")
                                Application.api.mutate {
                                    feed(feedUrl) {
                                        update(FeedInput(url?.takeIf { it != feedUrl }, show?.id, bundle, active))
                                    }
                                }.feed.update

                                if (url != feedUrl) {
                                    // if we're changing URLs, wait for the show to update
                                    waitForShowSourceUpdate(feed?.show?.id!!, feedUrl, false)
                                }

                                navigateBack()
                            }
                        }
                    }
                }
            }
        }
    }
}