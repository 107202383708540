package com.siriusxm.pia.views.channelguide

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.epg.ChannelSummary
import com.siriusxm.pia.rest.epg.LineupSummary
import com.siriusxm.pia.rest.epg.id
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text


@Composable
fun lineups(epg: EPG, lineupId: String? = null) {
    var channels by remember { mutableStateOf<List<ChannelSummary>?>(null) }
    var lineups by remember { mutableStateOf<List<LineupSummary>?>(null) }
    var selected by remember { mutableStateOf<LineupSummary?>(null) }

    LaunchedEffect("lineups") {
        val allLineups = epg.fetchAllLineups().sortedBy { it.lineupId }

        lineups = allLineups
        if (lineupId != null) {
            selected = allLineups.find { it.id == lineupId }
        }
    }

    LaunchedEffect(selected) {
        if (selected != null) {
            try {
                channels = null
                channels = epg.fetchChannelsForLineup(selected!!.id).sortedBy { it.channel.channelNumber }
            } catch (e: Exception) {
                channels = emptyList()
            }
        }
    }

    serviceContentView({
        title = "Lineups"
    }) {
        splitSelectionView<LineupSummary> {
            items = lineups

            renderListItem { lineup ->
                Text(lineup.lineupId.toString())
            }

            onSelect {
                selected = it
            }
            this.selection = selected

            column {
                content { lineup ->
                    lineup.name?.let {
                        Text(it)
                    }
                }
            }

            render { lineup ->
                entityView({
                    title = if (lineup.name != null) {
                        lineup.name + " (${lineup.lineupId})"
                    } else {
                        lineup.lineupId.toString()
                    }
                }) {
                    Div({}) {
                        if (selected != null) {
                            if (channels == null) {
                                spinner()
                            } else if (channels.isNullOrEmpty()) {
                                boxMessage("No channels found in this lineup.")
                            } else {
                                channels?.forEach { channel ->
                                    key(channel.id) {
                                        channelSummaryItem(epg.context, channel)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
