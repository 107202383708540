package com.siriusxm.pia.views.channelguide

import androidx.compose.runtime.*
import com.siriusxm.pia.ApplicationContext
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.epg.Category
import com.siriusxm.pia.rest.epg.ChannelDetailSummary
import com.siriusxm.pia.rest.epg.ChannelSummary
import com.siriusxm.pia.rest.epg.id
import contentingestion.unifiedmodel.EntityType
import contentingestion.unifiedmodel.Image
import contentingestion.unifiedmodel.ImageAspectRatio
import contentingestion.unifiedmodel.ImagePurpose
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import kotlin.time.Duration.Companion.seconds

enum class ViewFields {
    UUID,
    STREAMING_ID
}

class ViewSettings(
    val fields: List<ViewFields> = emptyList()
)

/**
 * A generic filter of channels
 */
interface Filter {
    val name: String

    fun filter(channel: ChannelSummary): Boolean
}

/**
 * Filter for business only
 */
class BusinessOnlyFilter : Filter {
    override val name: String = "Business Only"

    override fun filter(channel: ChannelSummary): Boolean {
        return channel.channel.businessOnly == true
    }
}

/**
 * Filter for channel types
 */
class EntityTypeFilter(val entityType: EntityType) : Filter {
    override val name: String = "Type: ${entityType.name}"

    override fun filter(channel: ChannelSummary): Boolean {
        return channel.channel.type == entityType.name
    }
}

/**
 * Category filter
 */
class CategoryFilter(val category: Category) : Filter {
    override val name: String = "Category: ${category.terms.joinToString(" > ")}"

    override fun filter(channel: ChannelSummary): Boolean {
        return channel.channel.categories.orEmpty().contains(category.id)
    }
}

/**
 * Filter for channels with no images.
 */
class NoImageFilter : Filter {
    override val name: String = "No Image"

    override fun filter(channel: ChannelSummary): Boolean {
        return channel.mainImage() == null
    }
}

/**
 * A text filter that compares a number of fields.
 */
class TextFilter(val text: String) : Filter {
    override val name: String = text

    override fun filter(channel: ChannelSummary): Boolean {
        return channel.channel.name.lowercase().contains(text.lowercase()) ||
                channel.id.contains(text.lowercase()) ||
                channel.channel.streamingId == text ||
                channel.channel.channelNumber.toString() == text
    }
}

@Composable
fun channelsBrowser(
    channels: List<ChannelDetailSummary>,
    categories: List<Category>,
    epg: EPG,
    categoryId: String? = null
) {
    val coroutineScope = rememberCoroutineScope()
    var viewSettings: ViewSettings by remember { mutableStateOf(ViewSettings()) }
    var category: Category? by mutableStateOf(categories.find { it.id == categoryId })
    var allChannels by remember {
        mutableStateOf(channels.map {
            ChannelSummary(it)
        }.sortedBy { it.channel.channelNumber })
    }
    var job: Job? by remember { mutableStateOf(null) }
    val availableFilters = listOf(
        BusinessOnlyFilter(),
        EntityTypeFilter(EntityType.CHANNEL_LINEAR),
        EntityTypeFilter(EntityType.CHANNEL_XTRA),
        NoImageFilter()
    ) + categories.map {
        CategoryFilter(it)
    }
    var filters: List<Filter> by remember { mutableStateOf(emptyList()) }

    fun applyFilters(channels: List<ChannelSummary>): List<ChannelSummary> {
        var result: List<ChannelSummary> = channels
        filters.forEach { filter ->
            result = result.filter {
                filter.filter(it)
            }
        }
        return result
    }

    LaunchedEffect(categoryId, categories) {
        category = categories.find { it.id == categoryId }
    }

    LaunchedEffect(Unit) {
        var lastCall: Instant? = null
        job?.cancel()
        job = coroutineScope.launch {
            try {
                while (true) {
                    val callTime = Clock.System.now()
                    val newChannels = run {
                        val currentAll = allChannels
                        val updated = epg.fetchAllChannels(lastCall?.minus(20.seconds)).associateBy { it.id }
                        currentAll.map {
                            updated[it.id] ?: it
                        }
                    }
                    lastCall = callTime
                    allChannels = newChannels
                    applyFilters(newChannels)
                    delay(5.seconds)
                }
            } catch (e: CancellationException) {
                // ignored
            }
        }
    }

    /**
     * The settings dialog for the view
     */
    @Composable
    fun DialogOverlayAttributes.settings() {
        var uuid by remember { mutableStateOf(viewSettings.fields.contains(ViewFields.UUID)) }
        var showStreamingId by remember { mutableStateOf(viewSettings.fields.contains(ViewFields.STREAMING_ID)) }

        title = "Channel View Preferences"
        save {
            viewSettings = ViewSettings(
                fields = listOfNotNull(
                    if (uuid) ViewFields.UUID else null,
                    if (showStreamingId) ViewFields.STREAMING_ID else null
                )
            )
        }
        content {
            Div({
                style {
                    padding(20.px)
                    display(DisplayStyle.Flex)
                    width(100.percent)
                    gap(10.px)
                }
            }) {
                Div({ style { flexBasis(100.percent) } }) {
                    H3({ style { marginTop(0.px) } }) {
                        Text("Fields")
                    }
                    Div {
                        simpleCheckBox("UUID", uuid) {
                            uuid = it
                        }
                        simpleCheckBox("Streaming Id", showStreamingId) {
                            showStreamingId = it
                        }
                    }
                }
            }
        }
    }

    serviceContentView({
        title = "Channels"
    }) {
        if (channels.isEmpty() || categories.isEmpty()) {
            spinner(size = Size.LARGE)
        } else {
            Div({
                style {
                    display(DisplayStyle.Flex)
                    position(Position.Relative)
                    gap(1.em)
                }
            }) {
                Div({
                    style { flex(1) }
                }) {
                    var filterInput: String? by remember { mutableStateOf(null) }
                    var selectedFilter: Filter? by remember { mutableStateOf(null) }
                    var matchedFilters: List<Filter> by remember { mutableStateOf(emptyList()) }
                    Input(InputType.Text) {
                        style { width(100.percent) }
                        value(filterInput ?: "")
                        placeholder("Enter name, category, id, etc.")
                        onInput { event ->
                            filterInput = event.value
                            matchedFilters = availableFilters.filter {
                                it.name.lowercase().contains(event.value.lowercase())
                            }
                        }
                        onKeyDown { event ->
                            when (event.key) {
                                "Enter" -> {
                                    event.stopPropagation()
                                    event.preventDefault()

                                    if (selectedFilter != null) {
                                        filters = (filters + listOfNotNull(selectedFilter)).distinctBy { it.name }
                                    } else if (!filterInput.isNullOrBlank()) {
                                        filters = filters + TextFilter(filterInput.orEmpty())
                                    }
                                    filterInput = null
                                    matchedFilters = emptyList()
                                    selectedFilter = null
                                }

                                "ArrowUp" -> {
                                    selectedFilter = if (selectedFilter != null) {
                                        val index = matchedFilters.indexOf(selectedFilter) - 1
                                        matchedFilters.getOrNull(index) ?: matchedFilters.lastOrNull()
                                    } else {
                                        matchedFilters.lastOrNull()
                                    }
                                }

                                "ArrowDown" -> {
                                    selectedFilter = if (selectedFilter != null) {
                                        val index = matchedFilters.indexOf(selectedFilter) + 1
                                        matchedFilters.getOrNull(index) ?: matchedFilters.firstOrNull()
                                    } else {
                                        matchedFilters.firstOrNull()
                                    }
                                }

                                "Escape" -> {
                                    if (filterInput != null) {
                                        filterInput = null
                                    } else {
                                        filters = emptyList()
                                    }
                                    matchedFilters = emptyList()
                                    selectedFilter = null
                                }
                            }
                        }
                    }
                    if (matchedFilters.isNotEmpty()) {
                        Div({
                            classes(ChannelGuideStyles.filterAutoComplete)
                        }) {
                            matchedFilters.forEach { matched ->
                                Div({
                                    classes(ChannelGuideStyles.filterAutoCompleteItem)
                                    if (selectedFilter == matched) {
                                        classes(ChannelGuideStyles.filterAutoCompleteItemSelected)
                                    }
                                    onClick {
                                        filters = filters + matched
                                        filterInput = null
                                        matchedFilters = emptyList()
                                        selectedFilter = null
                                    }
                                }) {
                                    Text(matched.name)
                                }
                            }
                        }
                    }
                    if (filters.isNotEmpty()) {
                        Div({
                            classes(ChannelGuideStyles.filterItemsContainer)
                        }) {
                            filters.forEach { filter ->
                                Span({
                                    classes(ChannelGuideStyles.filteredItem)
                                }) {
                                    Text(filter.name)
                                    icon("close") {
                                        size = IconSize.TINY
                                        action {
                                            filters = filters - filter
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                Div({
                }) {
                    dialogIcon {
                        settings()
                    }
                }
            }
        }

        Div({}) {
            val filtered = applyFilters(allChannels).map { it.id }
            if (allChannels.isNotEmpty() && filtered.isEmpty()) {
                boxMessage("No channels found that match this criteria")
            } else {
                allChannels.forEach { channel ->
                    key(channel.id) {
                        Div({
                            style {
                                if (!filtered.contains(channel.id)) {
                                    display(DisplayStyle.None)
                                }
                            }
                        }) {
                            channelSummaryItem(epg.context, channel, viewSettings.fields)
                        }
                    }
                }
            }

        }
    }
}

/**
 * Get the main image for a channel, a 16x9 TILE, or a 1x1 tile as a fallback.
 */
fun ChannelSummary.mainImage(): Image? {
    return channel.images?.get(ImagePurpose.TILE)?.let {
        it[ImageAspectRatio.ASPECT_16X9] ?: it[ImageAspectRatio.ASPECT_1X1]
    }?.default
}

/**
 * A list item to display a channel summary.
 */
@Composable
fun channelSummaryItem(
    context: ApplicationContext,
    channel: ChannelSummary,
    fields: List<ViewFields> = emptyList()
) {
    val currentChannel by rememberUpdatedState(channel)

    val channelUrl = "#channelguide/channels/${currentChannel.id}"
    Div({
        classes(ChannelGuideStyles.epgListItem)
    }) {
        Div({
            classes(ChannelGuideStyles.epgListItemLeading)
        }) {
            if (currentChannel.channel.type == EntityType.CHANNEL_XTRA.name) {
                Text("XTRA")
            } else {
                Text(currentChannel.channel.channelNumber.toString())
            }
        }
        Div({
            classes(ChannelGuideStyles.epgListItemImage)
        }) {
            A(href = channelUrl) {
                defaultImage(context, channel.channel.images, ImageAspectRatio.ASPECT_16X9, 128.px)
            }
        }
        Div({
            classes(ChannelGuideStyles.epgListItemMain)
        }) {
            Div {
                H3 {
                    A(href = channelUrl) {
                        Text(currentChannel.channel.name)
                    }
                    aggregatorIconLink(currentChannel.id)
                }
                currentChannel.channel.description?.let {
                    P {
                        Text(it)
                    }
                }
                if (fields.contains(ViewFields.UUID)) {
                    P {
                        Text(currentChannel.channel.id)
                    }
                }
                if (fields.contains(ViewFields.STREAMING_ID)) {
                    P {
                        Text("Streaming Id: ${currentChannel.channel.streamingId}")
                    }
                }
                if (currentChannel.channel.businessOnly == true) {
                    icon("apartment") {
                        size = IconSize.SMALL
                        title = "Business only"
                    }
                }
                val accessControls = currentChannel.channel.accessControls?.default
                Div({
                    classes(ChannelGuideStyles.epgIconContainer)
                }) {
                    if (accessControls?.visible == false) {
                        icon("visibility_off") {
                            size = IconSize.TINY
                            title = "Not visible"
                        }
                    }
                    if (accessControls?.discoverable == false) {
                        icon("hide_image") {
                            size = IconSize.TINY
                            title = "Not discoverable"
                        }
                    }
                    if (accessControls?.visible == false) {
                        icon("thumb_down") {
                            size = IconSize.TINY
                            title = "Not recommendable"
                        }
                    }
                }
            }
            Div {
                currentChannel.cuts?.firstOrNull()?.let { cut ->
                    H4 {
                        Text(cut.name)
                    }
                    cut.artist?.let {
                        P {
                            Text(it)
                        }
                    }
                }
            }
        }
        Div({
            classes(ChannelGuideStyles.epgListItemTrailing)
        })
    }
}
