package com.siriusxm.pia.views.mddb

import androidx.compose.runtime.*
import com.siriusxm.pia.ApplicationContext
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.mddb.JobInfo
import com.siriusxm.pia.rest.mddb.JobStatusResponse
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun mddbDashboardPage(context: ApplicationContext, mddbAPI: MddbAPI) {
    var jobStatuses by remember { mutableStateOf<JobStatusResponse?>(null) }

    LaunchedEffect("status") {
        jobStatuses = mddbAPI.pollingJobStatus()
    }

    serviceContentView({
        title = "MDDB"
    }) {
        if (jobStatuses == null) {
            spinner(size = Size.LARGE)
        } else {
            boxTable<JobInfo> {
                items(jobStatuses?.jobs.orEmpty())
                column {
                    content { pollType ->
                        Div {
                            Text(pollType.type)
                        }
                    }
                }
                column {
                    content { pollTypeStatus ->
                        Div {
                            if (pollTypeStatus.id != "no-active-job") {
                                Div {
                                    Text("Job: ${pollTypeStatus.id}")
                                }
                            }
                            pollTypeStatus.disabledUntil?.let {
                                Div {
                                    Text("Disabled Until: ${pollTypeStatus.disabledUntil}")
                                }
                            }
                        }
                    }
                }
                column {
                    width = 18.em
                    content { pollTypeStatus ->
                        val pollType = pollTypeStatus.type
                        Div {
                            if (pollTypeStatus.disabled) {
                                button {
                                    title = "Enable"
                                    action {
                                        mddbAPI.enablePolling(pollTypeStatus.type)

                                        context.info("Polling enabled for ${pollTypeStatus.type}.  Refresh the page to update the statuses")

                                        context.navigate("mddb-dashboard?pollType=${pollTypeStatus.type}")
                                    }
                                }
                            } else {
                                button {
                                    title = "Poll Now"
                                    action {
                                        mddbAPI.triggerPolling(pollType)
                                        context.info("Polling initiated for $pollType")
                                    }
                                }

                                button {
                                    title = "Disable"
                                    action {
                                        mddbAPI.disablePolling(pollType)
                                        context.info("Polling disabled for $pollType. Refresh the page to update the statuses")
                                        context.navigate("mddb-dashboard?pollType=$pollType")
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@Composable
private fun iconForBoolean(value: Boolean) {
    if (value) {
        icon("done")
    } else {
        icon("close")
    }
}
