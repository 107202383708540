package com.siriusxm.content.management.console.applications.transcription

import com.siriusxm.pia.rest.transcription.*
import de.jensklingenberg.ktorfit.Ktorfit
import io.ktor.client.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.json.Json

class TranscriptionAPI(url: String, private val authGetter: () -> String) {

    private val ktorfit = Ktorfit.Builder()
        .baseUrl(url)
        .httpClient(
            HttpClient {
                install(ContentNegotiation) {
                    json(Json { isLenient = true; ignoreUnknownKeys = true })
                }
            }
        )
        .build()
    private val transcriptionClient = ktorfit.create<TranscriptionClient>()

    suspend fun status() : String =
        transcriptionClient.status(headers())

    suspend fun getPreferredVersion(typedEntityId: String) : TranscriptionDetailSummary {
        console.log("About to fetch preferred version for: $typedEntityId")
        val result = transcriptionClient.preferredVersion(typedEntityId,
            headers()
        )
        console.log("Preferred version result: $result")
        return result
    }

    suspend fun getPreferredVersions(typedEntityIds: List<String>) : List<TranscriptionVersion> {
        console.log("About to fetch preferred versions for: [${typedEntityIds.joinToString(",")}]")
        val result = transcriptionClient.hasTranscriptions(
            HasTranscriptionsRequest(typedEntityIds),
            headers()
        )
        console.log("Preferred version result: $result")
        return result.results
    }

    suspend fun getActiveTranscriptions() : PaginatedTranscriptionJobs {
        val result = transcriptionClient.activeJobs(headers())
        console.log("Active transcriptions result: $result")
        return result
    }

    suspend fun getCompletedTranscriptions() : PaginatedTranscriptionJobs {
        val result = transcriptionClient.completedJobs(headers())
        console.log("Completed transcriptions result: $result")
        return result
    }

    suspend fun getDuplicatedTranscriptions() : PaginatedTranscriptionJobs {
        val result = transcriptionClient.duplicatedJobs(headers())
        console.log("Duplicated transcriptions result: $result")
        return result
    }

    suspend fun getFailedTranscriptions() : PaginatedTranscriptionJobs {
        val result = transcriptionClient.failedJobs(headers())
        console.log("Failed transcriptions result: $result")
        return result
    }

    suspend fun getJobsForEntityId(typedEntityId: String) : PaginatedTranscriptionJobs {
        val result = transcriptionClient.getJobsForEntityId(typedEntityId, headers())
        console.log("Jobs for entity id: $result")
        return result
    }

    suspend fun getJobById(jobId: String) : TranscriptionJob? {
        val result = transcriptionClient.getJobsById(jobId, headers())
        console.log("Job by jobId: $result")
        return result
    }

    suspend fun submitJob(request: TranscriptionRequest) : TranscriptionJob? {
        val result = transcriptionClient.submitJob(request, headers())
        console.log("Job submitted: $result")
        return result
    }

    suspend fun fetchVocabulary(vocabularyId: String) : VocabularyResponse {
        val result = transcriptionClient.fetchVocabulary(vocabularyId, headers())
        console.log("fetchVocabulary result: $result")
        return result
    }

    suspend fun updateVocabulary(vocabularyId: String, updateRequest: VocabularyUpdateRequest) : VocabularyResponse {
        // vocabulary api is a little bit rigid -- have to know if the vocabulary  exists to know which API to call
        var existingVocabulary: VocabularyResponse? = null
        try {
            existingVocabulary = fetchVocabulary(vocabularyId)
        } catch (e: Exception) {
            // not found exception is expected
        }

        return if (existingVocabulary != null) {
            val result = transcriptionClient.upsertVocabulary(vocabularyId, updateRequest, headers())
            console.log("updateVocabulary result: $result")
            result
        } else {
            // we need to create a new vocabulary
            val request = VocabularyRequest(vocabularyId, updateRequest.customVocabularyPhrases)
            val result = transcriptionClient.createVocabulary(request, headers()
            )
            console.log("createVocabulary result: $result")
            result
        }
    }

    suspend fun deleteVocabulary(vocabularyId: String) : Boolean {
        return try {
            transcriptionClient.deleteVocabulary(vocabularyId, headers())
            true
        } catch (e: Exception) {
            console.log("deleteVocabulary had an exception: $e")
            false
        }
    }

    private fun headers() = mapOf("Authorization" to "Bearer ${authGetter()}")
}