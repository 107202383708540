package com.siriusxm.pia.views.channelguide

import androidx.compose.runtime.*
import com.siriusxm.pia.components.NavigationItem
import com.siriusxm.pia.components.serviceView
import com.siriusxm.pia.components.simpleNavSidebar
import com.siriusxm.pia.rest.epg.Category
import com.siriusxm.pia.rest.epg.ChannelDetailSummary
import com.siriusxm.pia.utils.Route

val uuidRegex = "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}${'$'}".toRegex()

/**
 * Channel Routing
 */
@Composable
fun Route.channelGuideDashboard(epg: EPG) {
    var categories: List<Category>? by remember { mutableStateOf(null) }
    var channels: List<ChannelDetailSummary>? by remember { mutableStateOf(null) }

    LaunchedEffect(true) {
        categories = epg.categories
        channels = epg.channelDetails
        epg.initData { newChannels, newCategories ->
            channels = newChannels
            categories = newCategories
        }
    }

    serviceView({
        simpleNavSidebar(
            true, "EPG", listOf(
                NavigationItem("Channels", "channelguide"),
                NavigationItem("Lineups", "channelguide/lineups"),
                NavigationItem("Shows", "channelguide/shows")
            )
        )
    }) {
        switch {
            select("channels") {
                switch {
                    select(uuidRegex) {
                        channel(epg, match, this)
                    }

                    default {
                        val categoryId = parameter("categoryId")
                        if (channels != null && categories != null) {
                            channelsBrowser(channels!!, categories!!, epg, categoryId)
                        }
                    }
                }
            }

            select("lineups") {
                switch {
                    select(uuidRegex) {
                        lineups(epg, match)
                    }
                    default {
                        lineups(epg)
                    }
                }

            }

            select("shows") {
                switch {
                    select(uuidRegex) {
                        show(epg, this.match)
                    }
                    default {
                        shows(epg)
                    }
                }
            }

            select("episodes") {
                switch {
                    select(uuidRegex) {
                        episode(epg, this.match)
                    }
                }
            }

            default {
                if (channels != null && categories != null) {
                    channelsBrowser(channels!!, categories!!, epg)
                }
            }
        }
    }
}