package com.siriusxm.pia.views.mddb

import com.siriusxm.pia.rest.mddb.JobInfo
import com.siriusxm.pia.rest.mddb.JobStatusResponse
import com.siriusxm.pia.rest.mddb.MddbClient
import de.jensklingenberg.ktorfit.Ktorfit
import io.ktor.client.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.json.Json

class MddbAPI(url: String, private val authGetter: () -> String) {

    private val ktorfit = Ktorfit.Builder()
        .baseUrl(url)
        .httpClient(
            HttpClient {
                install(ContentNegotiation) {
                    json(Json { isLenient = true; ignoreUnknownKeys = true })
                }
                expectSuccess = true
            }
        )
        .build()
    private val mddbClient = ktorfit.create<MddbClient>()

    suspend fun status() : String? {
        return try {
            mddbClient.status()
        } catch (e: Throwable) {
            console.log("status() had an exception: $e")
            null
        }
    }

    suspend fun statusAuthenticated() : String? {
        return try {
            mddbClient.statusAuthenticated(headers())
        } catch (e: Throwable) {
            console.log("statusAuthenticated() had an exception: $e")
            null
        }
    }

    suspend fun enablePolling(entityType: String) : JobInfo?  {
        return try {
            mddbClient.enablePolling(entityType, headers())
        } catch (e: Throwable) {
            console.log("enablePolling($entityType) had an exception: $e")
            null
        }
    }
    suspend fun disablePolling(entityType: String) : JobInfo?  {
        return try {
            mddbClient.disablePolling(entityType, headers())
        } catch (e: Throwable) {
            console.log("disablePolling($entityType) had an exception: $e")
            null
        }
    }

    suspend fun pollingJobStatus() : JobStatusResponse?  {
        return try {
            mddbClient.pollingJobStatus(headers())
        } catch (e: Throwable) {
            console.log("pollingJobStatus() had an exception: $e")
            null
        }
    }

    suspend fun triggerPolling(entityType: String) : String? {
        return try {
            mddbClient.triggerPolling(entityType, headers())
        } catch (e: Throwable) {
            console.log("triggerPolling($entityType) had an exception: $e")
            null
        }
    }

    suspend fun fetchEntityById(typedEntityId: String) : String? {
        return try {
            mddbClient.fetchEntityById(typedEntityId, headers())
        } catch (e: Throwable) {
            console.log("fetchEntityById($typedEntityId) had an exception: $e")
            null
        }
    }
    suspend fun fetchRawEntity(typedEntityId: String) : String? {
        return try {
            mddbClient.fetchRawEntityById(typedEntityId, headers())
        } catch (e: Throwable) {
            console.log("fetchRawEntityById($typedEntityId) had an exception: $e")
            null
        }
    }

    suspend fun refreshEntity(typedEntityId: String) : Boolean {
        return try {
            mddbClient.refreshEntityById(typedEntityId, headers())
            true
        } catch (e: Throwable) {
            console.log("refreshEntityById($typedEntityId) had an exception: $e")
            false
        }
    }

    private fun headers() = mapOf("Authorization" to "Bearer ${authGetter()}")
}