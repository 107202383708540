package com.siriusxm.content.management.console.applications.transcription

import androidx.compose.runtime.*
import com.siriusxm.pia.utils.encodeURIComponent
import kotlinx.coroutines.CancellationException


/**
 * The main transcription page.
 */

@Composable
fun viewTranscriptionByAtlasId(atlasId: String, returnPath: String? = null) {

    var loading by mutableStateOf(false)
    var typedEntityId by mutableStateOf<String?>(null)

    LaunchedEffect(atlasId) {
        try {
            loading = true

            console.log("Looking up: $atlasId")
            typedEntityId = Transcriptions.aggregator.lookupSourceId(atlasId).sourceId
            console.log("Got: $typedEntityId")

            // Dispatch to the transcription by id page
            Transcriptions.navigation.navigate("transcriptions/${encodeURIComponent(typedEntityId!!)}?action=view")
        } catch (e: CancellationException) {
            // ignored
            console.log("Canceled exception: $e")
        } catch (t: Throwable) {
            console.log("Got an exception: $t")
        } finally {
            loading = false
        }
    }

}

