package com.siriusxm.pia

import com.siriusxm.pia.cognito.IdToken

/**
 * Encapsulates the application viewer.
 */
class Viewer(
    private val token: IdToken?
) {

    val contentEditor: Boolean by lazy {
        isInGroup(UserGroup.AggregatorAdmin) || isInGroup(UserGroup.AggregatorContentAdmin)
    }

    val operationsUser: Boolean by lazy {
        isInGroup(UserGroup.AggregatorAdmin) || isInGroup(UserGroup.AggregatorOperations)
    }

    val transcriptionAdmin: Boolean by lazy {
        isInGroup(UserGroup.AggregatorAdmin) || isInGroup(UserGroup.AggregatorTranscriptions)
    }

    val aggregatorAdmin: Boolean by lazy {
        isInGroup(UserGroup.AggregatorAdmin)
    }

    /**
     * Is the viewer a part of a given group
     */
    fun isInGroup(group: UserGroup): Boolean {
        return token?.cognitoGroups?.contains(group.id) ?: false
    }

    /**
     * Is the viewer a part of a given group
     */
    fun isInGroup(groupId: String): Boolean {
        return token?.cognitoGroups?.contains(groupId) ?: false
    }

    /**
     * Get the viewer's email address.
     */
    val email: String? get() = token?.email
}

enum class UserGroup(val id: String) {
    AggregatorAdmin("app-uni-content-aggregator-admin"),
    AggregatorContentAdmin("app-uni-content-contentadmin"),
    AggregatorOperations("app-uni-content-operations"),
    AggregatorTranscriptions("app-uni-content-transcriptions")
}