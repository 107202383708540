package com.siriusxm.content.management.console.applications.transcription

import androidx.compose.runtime.*
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.spinner
import com.siriusxm.pia.rest.transcription.TranscriptionJob
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch

/**
 * The main transcription page.
 */

@Composable
fun transcriptionsActivePage(returnPath: String? = null) {

    var activeTranscriptions by remember { mutableStateOf(emptyList<TranscriptionJob>()) }
    val coroutineScope = rememberCoroutineScope()
    var loading by mutableStateOf(false)
    var loadingJob by mutableStateOf<Job?>(null)

    suspend fun lookupActiveTranscriptions() {
        try {
            loading = true

            console.log("Begin looking update action transcriptions")
            val active = Transcriptions.api.getActiveTranscriptions()
            val activeJobs = mutableListOf<TranscriptionJob>()
            active.jobs.let { activeJobs.addAll(it) }
            activeTranscriptions = activeJobs

            console.log("Done looking update action transcriptions")
        } catch (e: CancellationException) {
            // ignored
        } catch (t: Throwable) {
            console.log("Got an exception: $t")
            activeTranscriptions = emptyList()
        } finally {
            loading = false
        }
    }


    box({
        paddedContent = activeTranscriptions.isEmpty() || loading
        header({
            title = "Active Transcriptions"
        })
    }) {
        if (loading) {
            spinner()
        } else if (activeTranscriptions.isEmpty() && loadingJob == null) {
            loadingJob = coroutineScope.launch {
                lookupActiveTranscriptions()
            }
        } else {
            transcriptionJobResultsTab(activeTranscriptions, returnPath ?: "#transcriptions-active")
        }
    }
}