package com.siriusxm.content.management.console.applications.transcription

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.transcription.*
import com.siriusxm.pia.utils.encodeURIComponent
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*

/**
 * The main transcription page.
 */

@Composable
fun editVocabulary(typedEntityId: String, vocabulary: MutableList<String>?, returnPath: String? = null) {

    val coroutineScope = rememberCoroutineScope()

    var loading by mutableStateOf(false)

    var activeVocabulary by remember { mutableStateOf(vocabulary) }
    var activeItem by remember { mutableStateOf<String?>(null) }

    var editingIndex by remember { mutableStateOf<Int?>(null) }
    var editingItem by remember { mutableStateOf<String?>(null) }

    var saving by mutableStateOf(false)
    var savingJob by mutableStateOf<Job?>(null)


    LaunchedEffect(typedEntityId) {
        try {
            loading = true

            console.log("Begin looking up vocabulary by id")
            val vocabularyResponse = Transcriptions.api.fetchVocabulary(typedEntityId)
            activeVocabulary = vocabularyResponse.summary?.vocabularyPhrases?.toMutableList() ?: mutableListOf<String>()
            console.log("Done looking up vocabulary by id")
        } catch (e: CancellationException) {
            // ignored
            console.log("Canceled exception: $e")
        } catch (t: Throwable) {
            console.log("Got an exception: $t")
            activeVocabulary = mutableListOf()
        } finally {
            loading = false
        }
    }


    suspend fun saveVocabulary(vocabularyId: String, vocabularyItems: List<String>) {
        try {
            saving = true

            console.log("Begin editing a vocabulary by id")
            Transcriptions.api.updateVocabulary(
                vocabularyId, VocabularyUpdateRequest(vocabularyItems)
            )

            // navigate to show the current state of the vocabulary
            Transcriptions.navigation.navigate(
                returnPath ?: "transcriptions/vocabulary/${
                    encodeURIComponent(
                        typedEntityId
                    )
                }?action=view"
            )
            console.log("Done saving vocabulary for: $typedEntityId")
        } catch (e: CancellationException) {
            // ignored
        } catch (t: Throwable) {
            Transcriptions.notifications.showError(
                "When save was attempted, an error was returned.",
                t.message
            )
        } finally {
            saving = false
        }
    }

    dialogView("Edit Vocabulary") {
        val vocab = activeVocabulary
        if (activeVocabulary != null) {
            content {
                box({
                    paddedContent = vocab.isNullOrEmpty() || saving
                    header({
                        title = "Vocabulary:  $typedEntityId"
                    })
                }) {

                    table<String> {

                        items(activeVocabulary)

                        column {
                            title = "Vocabulary Term"
                            width = 600.px
                            content { phrase ->
                                val index = activeVocabulary?.indexOf(phrase) ?: -1
                                if (editingIndex == null || editingIndex != index) {
                                    Div {
                                        Text(phrase)
                                    }
                                } else {
                                    Input(InputType.Text) {
                                        this.value(editingItem ?: "")
                                        onInput {
                                            editingItem = it.value.ifBlank { null }
                                        }
                                    }
                                }
                            }
                        }

                        column {
                            title = "Action"
                            width = 250.px
                            content { phrase ->
                                val index = vocab?.indexOf(phrase) ?: -1
                                if (editingIndex == null || editingIndex != index) {
                                    iconAction("edit") {
                                        val editIndex = vocab?.indexOf(phrase) ?: -1
                                        console.log("Edit object: $phrase index: $editIndex")

                                        editingIndex = editIndex
                                        editingItem = phrase
                                    }

                                    iconAction("delete") {
                                        console.log("Remove object: $phrase")
                                        val vocabList = mutableListOf<String>()
                                        vocab?.forEach{item ->
                                            if (item != phrase) {
                                                vocabList.add(item)
                                            }
                                        }
                                        activeItem = null
                                        activeVocabulary = vocabList
                                    }
                                } else {
                                    button("Save") {
                                        val item = editingItem
                                        if (item != null) {
                                            val vocabList = mutableListOf<String>()
                                            vocab?.forEachIndexed { index, v ->
                                                if (index != editingIndex) {
                                                    vocabList.add(v)
                                                } else {
                                                    vocabList.add(item)
                                                }
                                            }
                                            editingItem = null
                                            editingIndex = null

                                            activeVocabulary = vocabList
                                        }
                                    }
                                    button("Cancel") {
                                        editingItem = null
                                        editingIndex = null
                                    }
                                }
                            }
                        }
                    }

                    /// this means we are not in-line editing a row
                    if (editingIndex == null) {
                        dialogField("") {
                            Div {
                                Input(InputType.Text) {
                                    this.value(activeItem ?: "")
                                    onInput {
                                        activeItem = it.value.ifBlank { null }
                                    }
                                }
                                button("Add") {
                                    val item = activeItem
                                    if (item != null) {
                                        // need to validate the input item upon save
                                        val MAX_PHRASE_WORDS_COUNT = 12
                                        val MAX_WORD_CHARS_COUNT = 34
                                        val words = item.split("\\s+".toRegex())
                                        val tooLongWords = words.filter { it.length > MAX_WORD_CHARS_COUNT }

                                        if (vocab?.contains(item) == true) {
                                            Transcriptions.notifications.showError(
                                                "The vocabulary item '$item' already exists in this vocabulary and cannot be added."
                                            )
                                        } else if (item.isEmpty()) {
                                            Transcriptions.notifications.showError(
                                                "The vocabulary item cannot be empty."
                                            )
                                        } else if (!Regex(".*[a-zA-Z]+.*").matches(item)) {
                                            Transcriptions.notifications.showError(
                                                "The provided phrase '$item' is invalid; Phrases must contain at least one alphabetic character"
                                            )
                                        } else if (Regex(".*\\d.*").matches(item)) {
                                            Transcriptions.notifications.showError(
                                                "The provided phrase '$item' is invalid; Numbers are not allowed"
                                            )
                                        } else if (words.size > MAX_PHRASE_WORDS_COUNT) {
                                            Transcriptions.notifications.showError(
                                                "The provided phrase '$item' is invalid; Phrases cannot be longer than $MAX_PHRASE_WORDS_COUNT words."
                                            )
                                            //only alpha and punctuation characters are allowed (U+0000-U+002F) and (U+003A-U+007A)
                                        } else if(!Regex("[\u0000-\u002F\u003A-\u007A]*").matches(item)) {
                                            Transcriptions.notifications.showError(
                                                "The provided phrase '$item' is invalid; Only alpha and punctuation characters are allowed (U+0000-U+002F) and (U+003A-U+007A)"
                                            )
                                        } else if (tooLongWords.isNotEmpty()) {
                                            Transcriptions.notifications.showError(
                                                "The provided phrase '$item' is invalid; The following words (${tooLongWords.joinToString(",")}) violate the word-length constraint; Individual words cannot be longer than $MAX_WORD_CHARS_COUNT characters."
                                            )
                                        } else {
                                            val vocabList = mutableListOf<String>()
                                            vocab?.let { vocabList.addAll(it) }
                                            vocabList.add(item)
                                            activeItem = null
                                            activeVocabulary = vocabList
                                        }
                                    }
                                }
                            }
                        }
                    }

                    button("Save") {
                        savingJob =  coroutineScope.launch {
                            saveVocabulary(typedEntityId, vocab!!)
                        }
                    }

                    button("Clear All") {
                        activeItem = null
                        activeVocabulary = mutableListOf()
                    }

                    button("Cancel") {
                        Transcriptions.navigation.navigate(returnPath ?: "transcriptions/vocabulary")
                    }
                }
            }
        }
    }
}

