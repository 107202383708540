package com.siriusxm.pia.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.Div

@Composable
fun primaryContentArea(content: @Composable () -> Unit = {}) {
    Div({
        classes(ServiceViewStyles.primaryContent)
    }) {
        content()
    }
}